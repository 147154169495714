import { Patient } from '../../../api/patients';
import { Container } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { AssessmentIdOrLatest } from '../../../api/report';
import { WebHeader } from '../../patient-profile/WebheaderComponents/WebHeader';
import { ActiveModulesProvider } from '../../patient-profile/WebheaderComponents/ActiveModuleComponents/ActiveModulesProvider';

interface AssessmentFrameWithEditProps extends AssessmentFrameBaseProps {
  showEditButton: true;
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveEdit: () => void;
  handleAbortEdit: () => void;
}

interface AssessmentFrameWithoutEditProps extends AssessmentFrameBaseProps {
  showEditButton?: false;
}

interface AssessmentFrameBaseProps {
  patient: Patient;
  assessmentId?: AssessmentIdOrLatest;
  showAssessmentButton?: boolean;
  showEvaluationButton?: boolean;
  showDateDropdown?: boolean;
  showEditButton?: boolean;
  id: string;
}

type AssessmentFrameProps = AssessmentFrameWithEditProps | AssessmentFrameWithoutEditProps;

export function AssessmentFormFrame(props: PropsWithChildren<AssessmentFrameProps>) {
  const isEditModeProps = (
    props: Partial<AssessmentFrameProps>,
  ): props is AssessmentFrameWithEditProps => {
    return props.showEditButton === true;
  };
  return (
    <Container maxWidth="xl" sx={{ mt: '2rem' }}>
      <ActiveModulesProvider>
        {isEditModeProps(props) ? (
          <WebHeader
            patient={props.patient}
            assessmentId={props.assessmentId}
            showAssessmentButton={props.showAssessmentButton}
            showEvaluationButton={props.showEvaluationButton}
            showDateDropdown={props.showDateDropdown}
            showActiveModules
            showEditButton
            inEditMode={props.inEditMode}
            setInEditMode={props.setInEditMode}
            handleSaveEdit={props.handleSaveEdit}
            handleAbortEdit={props.handleAbortEdit}
          />
        ) : (
          <WebHeader
            patient={props.patient}
            assessmentId={props.assessmentId}
            showAssessmentButton={props.showAssessmentButton}
            showEvaluationButton={props.showEvaluationButton}
            showDateDropdown={props.showDateDropdown}
          />
        )}
        {props.children}
      </ActiveModulesProvider>
    </Container>
  );
}
