import React from 'react';
import { Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import { AssessmentFormType } from '../../AssessmentForm';
import { useTranslation } from 'react-i18next';

export function ErrorDialogue({
  open,
  setOpen,
  form,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  form: AssessmentFormType;
}) {
  const { t } = useTranslation('errorDialogue');
  const errors = form.formState.errors;
  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Form Errors</DialogTitle>
      <DialogContent>
        <List>
          {Object.entries(errors).map(([field, error]) => (
            <ListItem key={field}>
              <ListItemText primary={`${t(field)}: ${error.message}`} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
