import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { Patient, usePatientById } from '../../../api/patients';
import { AssessmentFormFrame } from '../assessmentForm/AssessmentFormFrame';
import { WebAssessment } from './web/WebAssessment';
import React, { useState } from 'react';
import { EmptyPlaceholderPage } from '../../core/EmptyPlaceholderPage';
import {
  PrefilledValuesForJamesLieAndJohnPotter,
  usePrefilledValuesForJamesLieAndJohnPotter,
} from '../../../config/featureConfiguration';
import { DEV_MODE } from '../../../config/urlConfiguration';
import { AssessmentCardEditModeProps } from '../assessmentForm/subComponents/AssessmentCard/AssessmentCard';
import { useLatestWebAssessment } from '../../../api/assessments';
import { LoadingIndicator } from '../../core/LoadingIndicator';
import { AssessmentParameters } from '../../../types/assessments';

export function WebInputAssessmentPage() {
  const { patientId } = useParams();
  const [inEditMode, setInEditMode] = useState(false);
  const [onAbortEditTrigger, setOnAbortEditTrigger] = useState(false);
  const [onSaveEditTrigger, setOnSaveEditTrigger] = useState(false);

  const handleSaveEdit = () => {
    setOnSaveEditTrigger(true);
  };

  const handleAbortEdit = () => {
    setOnAbortEditTrigger(true);
  };

  const editModeProps: AssessmentCardEditModeProps = {
    inEditMode: inEditMode,
    setEditMode: setInEditMode,
    onAbortEditTrigger: onAbortEditTrigger,
    onSaveEditTrigger: onSaveEditTrigger,
    setOnSaveEditTrigger: setOnSaveEditTrigger,
    setOnAbortEditTrigger: setOnAbortEditTrigger,
  };

  if (patientId === undefined) {
    throw Error('PatientId Not Found');
  }

  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));
  const { data: assessment, isLoading, isFetching } = useWithLoadingAndErrorIndication(
    useLatestWebAssessment(patientId),
  );

  if (patient === undefined) {
    return <EmptyPlaceholderPage />;
  }
  const assessmentParameters: AssessmentParameters | null =
    assessment && assessment.createdAt !== undefined
      ? ((({ createdAt, id, ...rest }) => rest)(assessment) as AssessmentParameters)
      : null;

  return (
    <AssessmentFormFrame
      patient={patient}
      id={patientId}
      showAssessmentButton
      showEditButton
      inEditMode={inEditMode}
      setInEditMode={setInEditMode}
      handleSaveEdit={handleSaveEdit}
      handleAbortEdit={handleAbortEdit}
    >
      {isLoading || isFetching ? (
        <LoadingIndicator relativeVerticalPosition={100} />
      ) : assessmentParameters !== null ? (
        <WebAssessment
          patient={patient}
          patientId={patientId}
          editModeProps={editModeProps}
          prefilledValues={assessmentParameters}
        />
      ) : fillValuesForJamesLieOrJohnPotter(patient) ? (
        <WebAssessment
          patient={patient}
          patientId={patientId}
          editModeProps={editModeProps}
          prefilledValues={PrefilledValuesForJamesLieAndJohnPotter}
        />
      ) : (
        <WebAssessment patient={patient} patientId={patientId} editModeProps={editModeProps} />
      )}
    </AssessmentFormFrame>
  );
}

function fillValuesForJamesLieOrJohnPotter(patient: Patient) {
  return (
    usePrefilledValuesForJamesLieAndJohnPotter &&
    DEV_MODE &&
    ((patient.firstName === 'James' && patient.lastName === 'Lie') ||
      (patient.firstName === 'John' && patient.lastName === 'Potter'))
  );
}
