import axios, { AxiosError } from 'axios';
import { ASSESSMENTS_URL, PATIENTS_URL } from '../config/urlConfiguration';
import { useQuery } from 'react-query';
import { getRedoxLatestAssessment } from './redox';
import { Assessment, AssessmentParameters } from '../types/assessments';
import { AssessmentIdOrLatest } from './report';

async function create(
  assessment: Partial<AssessmentParameters>,
  patientId: number,
  date: Date | undefined,
) {
  return (
    await axios.post(ASSESSMENTS_URL, {
      patientId,
      assessment,
      date,
    })
  ).data;
}

async function update(assessment: Assessment): Promise<Assessment> {
  return (await axios.put(`${ASSESSMENTS_URL}/${assessment.id}`, assessment)).data;
}

async function getLatestAssessment(patientId: string): Promise<Assessment> {
  const result = (await axios.get(`${PATIENTS_URL}/${patientId}/latestAssessment`)).data;
  return result.createdAt ? { ...result, createdAt: new Date(result.createdAt) } : {};
}

async function getAssessment(
  patientId: string,
  assessmentId: AssessmentIdOrLatest,
): Promise<Assessment> {
  const result = (await axios.get(`${PATIENTS_URL}/${patientId}/assessment/${assessmentId}`)).data;
  return { ...result, createdAt: new Date(result.createdAt) };
}

export function useLatestRedoxAssessment(sessionId: string) {
  return useQuery<Assessment, AxiosError>(['patient', sessionId, 'latestAssessment'], () =>
    getRedoxLatestAssessment(sessionId),
  );
}

export function useLatestWebAssessment(patientId: string) {
  return useQuery<Assessment, AxiosError>(['patient', patientId, 'latestAssessment'], () =>
    getLatestAssessment(patientId),
  );
}

export function useWebAssessment(patientId: string, assessmentId: AssessmentIdOrLatest) {
  return useQuery<Assessment, AxiosError>(['patient', patientId, 'assessment', assessmentId], () =>
    assessmentId === 'latest'
      ? getLatestAssessment(patientId)
      : getAssessment(patientId, assessmentId),
  );
}

export const assessmentsApi = {
  create,
  update,
};
