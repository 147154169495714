import { TFunction } from 'i18next';
import { RegisterOptions, ValidationRule } from 'react-hook-form';

export type MinMaxType<T extends string | number | symbol> = {
  [key in T]: {
    min: number;
    max: number;
  };
};

export function ruleHelper<T extends MinMaxType<keyof T>>(
  t: TFunction,
  allowedValues: T,
  key: keyof T,
  required: boolean = false,
  options?: {
    disabled?: boolean;
    decimal?: boolean;
  },
): RegisterOptions {
  const { disabled = undefined, decimal = false } = options || {};
  const numberPattern: ValidationRule<RegExp> =
    decimal !== undefined && decimal
      ? {
          value: /^\d+(\.\d+)?$/,
          message: t('errors.decimal'),
        }
      : {
          value: /^[\d*]+$/,
          message: t('errors.integer'),
        };
  const req = required ? { required: t('errors.required') } : {};
  return {
    ...req,
    min: {
      value: allowedValues[key].min,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    max: {
      value: allowedValues[key].max,
      message: t('errors.between', { min: allowedValues[key].min, max: allowedValues[key].max }),
    },
    setValueAs: (value) => (value === '' ? null : value),
    disabled: disabled,
    pattern: numberPattern,
  };
}
