import { Patient } from '../../../api/patients';
import SubHeader from '../../core/Header/SubHeader/SubHeader';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { AssessmentButton } from '../NavigationComponents/AssessmentButton';
import { AssessmentIdOrLatest } from '../../../api/report';
import { EvaluationButton } from '../NavigationComponents/EvaluationButton';
import { AssessmentDateDropdown } from '../NavigationComponents/AssessmentDateDropdown';
import { TimeseriesButton } from '../NavigationComponents/TimeseriesButton';
import { DifferenceButton } from '../NavigationComponents/DifferenceButton';
import { EditButton } from '../NavigationComponents/EditButton';
import { ActiveModulesCheckboxes } from './ActiveModuleComponents/ActiveModulesCheckboxes';

interface WebHeaderBaseProps {
  patient: Patient;
  showTrendButtons?: boolean;
  showPrint?: boolean;
  showEvaluationButton?: boolean;
  showAssessmentButton?: boolean;
  showDateDropdown?: boolean;
  assessmentId?: AssessmentIdOrLatest;
  showEditButton?: boolean;
  showActiveModules?: boolean;
}

interface WebHeaderWithEditProps extends WebHeaderBaseProps {
  showEditButton: true;
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveEdit: () => void;
  handleAbortEdit: () => void;
}

interface WebHeaderWithoutEditProps extends WebHeaderBaseProps {
  showEditButton?: false;
}

type WebHeaderProps = WebHeaderWithEditProps | WebHeaderWithoutEditProps;

export function WebHeader({
  patient,
  showAssessmentButton = false,
  showEvaluationButton = false,
  showDateDropdown = false,
  assessmentId,
  showTrendButtons = false,
  showActiveModules = false,
  ...editModeProps
}: WebHeaderProps) {
  const isEditModeProps = (props: Partial<WebHeaderProps>): props is WebHeaderWithEditProps => {
    return props.showEditButton === true;
  };
  if (!assessmentId) {
    assessmentId = 'latest';
  }
  return (
    <SubHeader>
      <Grid container alignContent={'baseline'}>
        <Grid item sx={{ mt: 2, pb: 0, mb: 0 }} justifyContent={'center'} xs={10} xl={11}>
          <Stack direction={'row'}>
            {showAssessmentButton && (
              <AssessmentButton patientId={patient.id} assessmentId={assessmentId} />
            )}
            {showEvaluationButton && (
              <EvaluationButton patientId={patient.id.toString()} assessmentId={assessmentId} />
            )}
            {showTrendButtons && <TimeseriesButton patientId={patient.id} />}
            {showTrendButtons && <DifferenceButton patientId={patient.id} />}
            {showActiveModules && <ActiveModulesCheckboxes />}
          </Stack>
        </Grid>
        {isEditModeProps(editModeProps) ? (
          <Grid item sx={{ pt: 1 }} xs={2} xl={1}>
            <EditButton
              inEditMode={editModeProps.inEditMode}
              setInEditMode={editModeProps.setInEditMode}
              handleAbortEdit={editModeProps.handleAbortEdit}
              saveEdit={editModeProps.handleSaveEdit}
            />
          </Grid>
        ) : null}

        <Grid item sx={{ pt: 1 }} xs={2} xl={1}>
          <Stack direction={'row'} spacing={3} justifyContent={'flex-end'}>
            {showDateDropdown && (
              <AssessmentDateDropdown
                patientId={patient.id.toString()}
                assessmentId={assessmentId}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </SubHeader>
  );
}
