import { AssessmentFormType } from '../../AssessmentForm';
import { Box, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DropdownNonEditable } from '../../subComponents/DropdownNonEditable';
import {
  antiArrhythmicMedication_Class1or3_keys,
  antidiabeticsKeys,
  antihypertensivesKeys,
  antiplateletMedicationKeys,
  contraindicationForOAC_keys,
  heartfailureMedicationKeys,
  lipidLoweringAgentKeys,
  oralAnticoagulantKeys,
} from './medications.logic';
import { statinDosagesPerMedication } from '../../../../../types/assessments';
import { GroupedMedicationsNonEditable } from './components/GroupedMedicationsNonEditable';

export default function MedicationsNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const values = form.getValues();
  return (
    <>
      <Divider sx={{ mb: 1 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={antihypertensivesKeys}
        headline={'Antihypertensives'}
      />
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={antidiabeticsKeys}
        headline={'Antidiabetics'}
        columnCount={2}
      />
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={antiplateletMedicationKeys}
        headline={'Antiplatelet medication'}
        columnCount={2}
      />

      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={heartfailureMedicationKeys}
        headline={'Heart failure medication'}
        columnCount={3}
      />
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={lipidLoweringAgentKeys}
        headline={'Lipid Lowering agent'}
        columnCount={3}
      />
      {values.statinDosage !== null ? (
        <Box pl={3} pb={1}>
          <DropdownNonEditable
            title={t(`medications.statinDosage.label`)}
            value={`${statinDosagesPerMedication[values.statin][values.statinDosage]} 
              (${t(`medications.statinDosage.values.${values.statinDosage}.tooltip`.toString())})`}
            withDivider={false}
            key={'statinDosage'}
            textAlign={'left'}
          />
        </Box>
      ) : null}
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={oralAnticoagulantKeys}
        headline={'Oral anticoagulant'}
        showTitle={false}
      />
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={antiArrhythmicMedication_Class1or3_keys}
        headline={'Antiarrhythmics (Class I or III)'}
        showTitle={false}
      />
      <Divider sx={{ my: 2 }} />
      <GroupedMedicationsNonEditable
        form={form}
        medicationKeys={contraindicationForOAC_keys}
        headline={'Contraindication for OAC'}
        showTitle={false}
      />
    </>
  );
}
