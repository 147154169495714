import { Box, Checkbox, Grid, ListItem, Slide, Typography } from '@mui/material';
import React from 'react';
import { DocumentPopup } from '../../../core/DocumentPopup/DocumentPopup';
import { Recommendation } from '../../../../api/patients';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { getRecommendationCategory, RecommendationCategory } from '../../../../api/report';
import { recommendationSortFunction } from './recommendationItem.logic';
import { slidingRecommendationsTransitionTime } from '../../../../config/constants';

export function GroupedRecommendations({
  recommendations,
  chosenCategory,
  onToggle,
  refForSlidingRecommendations,
}: {
  recommendations: Recommendation[];
  chosenCategory: RecommendationCategory;
  onToggle: (r: Recommendation) => void;
  refForSlidingRecommendations: React.RefObject<HTMLUListElement>;
}) {
  const filteredRecommendations = recommendations
    .filter((rec) => getRecommendationCategory(rec) === chosenCategory)
    .sort(recommendationSortFunction);

  const groupedRecommendations = groupRecommendationsByTitle(filteredRecommendations);

  return (
    <>
      {Object.entries(groupedRecommendations).map(([title, titleRecommendations], groupIndex) => {
        // Further group recommendations by parameters
        const paramGroups = groupByParameters(titleRecommendations);

        return (
          <Slide
            key={`group-${groupIndex}`}
            direction={'left'}
            in={true}
            container={refForSlidingRecommendations.current}
            timeout={slidingRecommendationsTransitionTime}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12}>
              {/* Group Title */}
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, mb: 1, px: 2 }}>
                {title}
              </Typography>

              {paramGroups.map((paramGroup, paramGroupIndex) => {
                const { representativeRec, recommendations } = paramGroup;
                const hasParameters =
                  Object.keys(representativeRec.recommendedParameters).length > 0;
                for (const r of recommendations) {
                  if (r !== representativeRec) {
                    r.applied = false;
                  }
                }
                return (
                  <ListItem
                    key={`param-group-${paramGroupIndex}`}
                    sx={{
                      py: 1,
                      alignItems: 'flex-start',
                      position: 'relative',
                    }}
                  >
                    <Checkbox
                      disabled={representativeRec.disabled}
                      icon={
                        representativeRec.disabled || !hasParameters ? <ListAltIcon /> : undefined
                      }
                      checked={hasParameters ? representativeRec.applied : false}
                      onChange={(event) => {
                        // Toggle the representative recommendation
                        onToggle({
                          ...representativeRec,
                          applied: event.target.checked,
                        });
                      }}
                      sx={{
                        pointerEvents: hasParameters ? undefined : 'none',
                        p: 0,
                        mr: 1,
                        mt: '2px',
                      }}
                      color={'primary'}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'flex-start',
                        maxWidth: '90%',
                      }}
                    >
                      {/* Display all recommendation texts in this parameter group */}
                      {paramGroup.recommendations.map((rec, recIndex) => (
                        <Box
                          key={`rec-text-${rec.key}`}
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            width: '100%',
                            position: 'relative',
                            ...(recIndex > 0 ? { mt: 1 } : {}),
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              whiteSpace: 'pre-wrap',
                              textAlign: 'justify',
                              pr: 3,
                            }}
                          >
                            {rec.text}
                          </Typography>

                          <Box
                            sx={{
                              position: 'absolute',
                              right: -24,
                              top: '50%',
                              transform: 'translateY(-50%)',
                            }}
                          >
                            <DocumentPopup
                              document={{
                                title: title,
                                sections: rec.information,
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </ListItem>
                );
              })}
            </Grid>
          </Slide>
        );
      })}
    </>
  );
}

function groupRecommendationsByTitle(
  recommendations: Recommendation[],
): Record<string, Recommendation[]> {
  return recommendations.reduce((groups, recommendation) => {
    const title = recommendation.title;
    if (!groups[title]) {
      groups[title] = [];
    }
    groups[title].push(recommendation);
    return groups;
  }, {} as Record<string, Recommendation[]>);
}

// Helper function to check if two sets of recommendedParameters are equal
function areParametersEqual(params1: Record<string, any>, params2: Record<string, any>): boolean {
  const keys1 = Object.keys(params1);
  const keys2 = Object.keys(params2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every(
    (key) => keys2.includes(key) && JSON.stringify(params1[key]) === JSON.stringify(params2[key]),
  );
}

// Helper function to group recommendations by their recommendedParameters
function groupByParameters(
  recs: Recommendation[],
): Array<{
  recommendations: Recommendation[];
  representativeRec: Recommendation;
}> {
  const result: Array<{
    recommendations: Recommendation[];
    representativeRec: Recommendation;
  }> = [];

  recs.forEach((rec) => {
    const existingGroup = result.find((group) =>
      areParametersEqual(group.representativeRec.recommendedParameters, rec.recommendedParameters),
    );

    if (existingGroup) {
      existingGroup.recommendations.push(rec);
    } else {
      result.push({
        recommendations: [rec],
        representativeRec: rec,
      });
    }
  });

  return result;
}
