import React from 'react';
import { Grid, Stack } from '@mui/material';
import {
  AbortEditAssessmentCardIcon,
  EditAssessmentCardIcon,
  SaveEditAssessmentCardIcon,
} from '../../assessment/assessmentForm/subComponents/AssessmentCard/AssessmentCardIcons';

export interface EditProps {
  inEditMode: boolean;
  setInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleAbortEdit: () => void;
  saveEdit: () => void;
}

export function EditButton({ inEditMode, setInEditMode, saveEdit, handleAbortEdit }: EditProps) {
  const startEdit = () => setInEditMode(true);
  const abortEdit = () => {
    handleAbortEdit();
    setInEditMode(false);
  };

  return (
    <Grid container direction={'row'} justifyContent={'space-between'} sx={{ pb: 1 }}>
      {inEditMode ? (
        <Stack direction={'row'}>
          <AbortEditAssessmentCardIcon onClick={abortEdit} />
          <SaveEditAssessmentCardIcon onClick={saveEdit} />
        </Stack>
      ) : (
        <EditAssessmentCardIcon onClick={startEdit} />
      )}
    </Grid>
  );
}
