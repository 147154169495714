import { Box, List, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GroupedMedicationProps, keyEnumMapper, separateKeys } from './groupedMedication';
import { CheckboxWithLabelAndTooltip } from '../../../../../core/input-components/CheckboxWithLabelAndTooltip';
import React from 'react';
import { DropdownEditable } from '../../../subComponents/DropdownEditable';

export function GroupedMedicationsEditable({
  form,
  medicationKeys,
  headline,
  columnCount,
  showTitle,
}: GroupedMedicationProps) {
  const { t } = useTranslation('forms');
  const { booleanKeys, enumKeys } = separateKeys(medicationKeys);

  return (
    <>
      <Typography variant={'h6'}>{headline}</Typography>
      {booleanKeys.length > 0 ? (
        <List
          disablePadding={true}
          sx={{ columnCount: columnCount === undefined ? 2 : columnCount, pb: 0, pl: 2 }}
          dense
        >
          <Stack direction={'column'}>
            {booleanKeys.map((key) => (
              <CheckboxWithLabelAndTooltip
                key={key}
                label={t(`medications.${key}.label`)}
                tooltip={t(`medications.${key}.tooltip`)}
                name={key}
                control={form.control}
              />
            ))}
          </Stack>
        </List>
      ) : null}
      {enumKeys.map((key) => {
        const mappedValue = keyEnumMapper(key);
        return mappedValue !== undefined ? (
          <Box mt={2} pl={3} pb={1} key={key}>
            <DropdownEditable
              isColumn={true}
              name={key}
              tooltip={t(`medications.${key}.tooltip`, '')}
              label={t(`medications.${key}.label`)}
              options={Object.values(mappedValue).map((v) => ({
                ...t(`medications.${key}.values.` + v, { returnObjects: true }),
                value: v,
              }))}
              control={form.control}
              errors={form.formState.errors}
              showLabel={showTitle}
            />
          </Box>
        ) : null;
      })}
    </>
  );
}
