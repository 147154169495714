import { List, ListItem } from '@mui/material';
import React from 'react';
import { CheckboxWithLabelAndTooltip } from '../../../../core/input-components/CheckboxWithLabelAndTooltip';
import { useTranslation } from 'react-i18next';
import { AssessmentFormType } from '../../AssessmentForm';
import { disabledMedicalHistoryKeys, tickableMedicalHistoryKeys } from './medicalHistory.logic';

export default function MedicalHistoryEditable({
  form,
  showHeartFailureSubparameters,
}: {
  form: AssessmentFormType;
  showHeartFailureSubparameters: boolean;
}) {
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Medication form={form} showHeartFailureSubparameters={showHeartFailureSubparameters} />
    </div>
  );
}

function Medication({
  form,
  showHeartFailureSubparameters,
}: {
  form: AssessmentFormType;
  showHeartFailureSubparameters: boolean;
}) {
  const { t } = useTranslation('forms');

  return (
    <List disablePadding={true} sx={{ columnCount: 2 }} dense>
      {disabledMedicalHistoryKeys.map((field) => (
        <ListItem disableGutters={true} key={field}>
          <CheckboxWithLabelAndTooltip
            name={field}
            label={t(`medicalHistory.${field}.label`)}
            tooltip={t(`medicalHistory.${field}.tooltip`)}
            control={form.control}
            disabled
          />
        </ListItem>
      ))}

      {tickableMedicalHistoryKeys
        .filter((key) => key !== 'heartFailure' && key !== 'HFpEF' && key !== 'HFrEF')
        .map((field) => (
          <ListItem disableGutters={true} key={field}>
            <CheckboxWithLabelAndTooltip
              name={field}
              label={t(`medicalHistory.${field}.label`)}
              tooltip={t(`medicalHistory.${field}.tooltip`)}
              control={form.control}
            />
          </ListItem>
        ))}
      <ListItem disableGutters={true} key={'heartFailure'}>
        <CheckboxWithLabelAndTooltip
          name={'heartFailure'}
          label={t(`medicalHistory.heartFailure.label`)}
          tooltip={t(`medicalHistory.heartFailure.tooltip`)}
          control={form.control}
        />
      </ListItem>
      {showHeartFailureSubparameters &&
        tickableMedicalHistoryKeys
          .filter((key) => key === 'HFpEF' || key === 'HFrEF')
          .map((field) => (
            <ListItem disableGutters={true} key={field} sx={{ pl: 3 }}>
              <CheckboxWithLabelAndTooltip
                name={field}
                label={t(`medicalHistory.${field}.label`)}
                tooltip={t(`medicalHistory.${field}.tooltip`)}
                control={form.control}
              />
            </ListItem>
          ))}
    </List>
  );
}
