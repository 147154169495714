import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { Divider, Grid, Paper, SxProps, Theme, Tooltip } from '@mui/material';
import { AssessmentFormType, FormParameter } from '../../AssessmentForm';
import AssessmentCardHeading from './AssessmentCardHeading';
import { useTranslation } from 'react-i18next';

export interface AssessmentCardEditModeProps {
  inEditMode: boolean;
  setEditMode: (inEditMode: boolean) => void;
  onAbortEditTrigger: boolean;
  onSaveEditTrigger: boolean;
  setOnSaveEditTrigger: (onSaveEditTrigger: boolean) => void;
  setOnAbortEditTrigger: (onAbortEditTrigger: boolean) => void;
}

interface AssessmentCardProps {
  sx?: SxProps<Theme>;
  title: string;
  notEditableChildren: ReactNode;
  editableChildren: ReactNode;
  onAbortEdit: () => void;
  form: AssessmentFormType;
  inputValues: Array<keyof Partial<FormParameter>>;
  editModeProps?: AssessmentCardEditModeProps;
  editable: boolean;
}

export default function AssessmentCard(props: AssessmentCardProps) {
  if (!props.editModeProps) {
    return (
      <NonEditableAssessmentCard
        notEditableChildren={props.notEditableChildren}
        sx={props.sx}
        title={props.title}
      />
    );
  }
  return (
    <EditableAssessmentCard
      editModeProps={props.editModeProps}
      inputValues={props.inputValues}
      form={props.form}
      notEditableChildren={props.notEditableChildren}
      editableChildren={props.editableChildren}
      sx={props.sx}
      onAbortEdit={props.onAbortEdit}
      title={props.title}
    />
  );
}

function NonEditableAssessmentCard({
  sx,
  notEditableChildren,
  title,
}: {
  sx?: SxProps<Theme>;
  notEditableChildren: ReactNode;
  title: string;
}) {
  return (
    <Paper elevation={2} sx={{ m: '1rem', p: '2rem', ...sx }}>
      <Grid container direction={'row'} justifyContent={'space-between'} sx={{ pb: 1 }}>
        {/*The Children Have to be rendered at all times to let
      the form elements stay registered, which get triggered on validation*/}
        <AssessmentCardHeading text={title} />
      </Grid>
      <div>{notEditableChildren}</div>
    </Paper>
  );
}

function EditableAssessmentCard({
  editModeProps,
  sx,
  notEditableChildren,
  editableChildren,
  onAbortEdit,
  title,
}: {
  editModeProps: AssessmentCardEditModeProps;
  inputValues: Array<keyof Partial<FormParameter>>;
  form: AssessmentFormType;
  sx?: SxProps<Theme>;
  notEditableChildren: ReactNode;
  editableChildren: ReactNode;
  onAbortEdit: () => void;
  title: string;
}) {
  const { setEditMode, onAbortEditTrigger, setOnAbortEditTrigger } = editModeProps || {};
  const { t } = useTranslation('forms');
  const assessmentCardRef = useRef<HTMLDivElement | null>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (onAbortEditTrigger) {
      onAbortEdit();
      setEditMode(false);
      setOnAbortEditTrigger(false);
    }
  }, [setEditMode, setOnAbortEditTrigger, onAbortEditTrigger, onAbortEdit]);

  const handleAssessmentCardClick = (event: any) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ x: clientX, y: clientY });
    setTooltipOpen(true);

    // Automatically close the tooltip after a short delay
    setTimeout(() => {
      setTooltipOpen(false);
    }, 4000);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (assessmentCardRef.current && !assessmentCardRef.current.contains(event.target as Node)) {
        setTooltipOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Paper elevation={2} sx={{ m: '1rem', p: '2rem', ...sx }}>
      <Grid container direction={'row'} sx={{ pb: 1 }}>
        {editModeProps.inEditMode ? <Divider sx={{ mb: 1.5 }} /> : null}
        {/*The Children Have to be rendered at all times to let
      the form elements stay registered, which get triggered on validation*/}
        <AssessmentCardHeading text={title} />
      </Grid>
      <div style={{ display: editModeProps.inEditMode ? undefined : 'none' }}>
        {editableChildren}
      </div>
      <ClickEditButtonTooltip
        tooltipOpen={tooltipOpen}
        tooltipPosition={tooltipPosition}
        tooltipText={t('clickEditButtonButtonTooltip')}
      >
        <div
          style={{ display: editModeProps.inEditMode ? 'none' : undefined }}
          onClick={handleAssessmentCardClick}
          ref={assessmentCardRef}
        >
          {notEditableChildren}
        </div>
      </ClickEditButtonTooltip>
    </Paper>
  );
}

function ClickEditButtonTooltip({
  children,
  tooltipOpen,
  tooltipPosition,
  tooltipText,
}: {
  tooltipOpen: boolean;
  tooltipPosition: { x: number; y: number };
  tooltipText: string;
  children: ReactElement;
}) {
  return (
    <Tooltip
      open={tooltipOpen}
      title={tooltipText}
      placement="top"
      PopperProps={{
        anchorEl: {
          getBoundingClientRect: () =>
            ({
              top: tooltipPosition.y,
              left: tooltipPosition.x,
              right: tooltipPosition.x,
              bottom: tooltipPosition.y,
              width: 0,
              height: 0,
              x: tooltipPosition.x,
              y: tooltipPosition.y,
              toJSON: () => ({}), // Provide a dummy implementation for toJSON
            } as DOMRect), // Type assertion to DOMRect
        },
      }}
    >
      {children}
    </Tooltip>
  );
}
