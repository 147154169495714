import { AssessmentFormType } from '../../AssessmentForm';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { NumericalParameterLine } from '../../subComponents/NumericalParameterLine';
import React from 'react';
import { LAB_UNITS, labParameterKeys } from './Lab.logic';

export function LabNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const values = form.getValues();

  return (
    <Stack>
      {labParameterKeys.map((fieldName, index, keys) => (
        <NumericalParameterLine
          title={t(`lab.${fieldName}.label`)}
          value={values[fieldName]}
          unitLabel={LAB_UNITS[fieldName]}
          withDivider={index !== keys.length - 1}
          tooltip={t(`lab.${fieldName}.tooltip`, '')}
          key={keys[index]}
        />
      ))}
    </Stack>
  );
}
