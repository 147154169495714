import { Divider, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { theme } from '../../../../theme';
import LabelWithTooltip from '../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export interface NonEditableAssessmentListItemProps {
  label: string;
  tooltip?: string;
  withDivider: boolean;
  isChecked: boolean | any;
}

export function NonEditableAssessmentListItem({
  label,
  tooltip,
  isChecked,
  withDivider,
}: NonEditableAssessmentListItemProps) {
  return (
    <>
      <ListItem disableGutters={true} key={label}>
        <ListItemIcon>
          {isChecked ? (
            <CheckBoxIcon style={{ color: theme.palette.primary.main }} />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ color: theme.palette.secondary.dark }} />
          )}
        </ListItemIcon>
        <ListItemText>
          <LabelWithTooltip label={label} tooltipText={tooltip} />
        </ListItemText>
      </ListItem>
      {withDivider && <Divider component="li" />}
    </>
  );
}
