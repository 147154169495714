import { Box, Stack, Switch, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PreventRisks, RiskHistoryCKM } from '../../../../types/risk';
import { IndividualRisk } from './IndividualRisk';
import { useTranslation } from 'react-i18next';

interface RisksProps {
  riskHistory: RiskHistoryCKM;
}

const MAX_RISK_VALUE = 0.07;

export default function RisksCKM(props: RisksProps) {
  const { t } = useTranslation('pages');
  const [tenYears, setTenYears] = useState<boolean>(true);
  const riskKey: keyof PreventRisks = tenYears ? 'tenYearsRiskEstimate' : 'thirtyYearsRiskEstimate';

  const {
    riskBeforeCVD,
    riskBeforeASCVD,
    riskBeforeHeartFailure,
    riskAfterCVD,
    riskAfterASCVD,
    riskAfterHeartFailure,
  } = {
    riskBeforeCVD: props.riskHistory.before[riskKey].CVD / 100,
    riskBeforeASCVD: props.riskHistory.before[riskKey].ASCVD / 100,
    riskBeforeHeartFailure: props.riskHistory.before[riskKey].heartFailure / 100,
    riskAfterCVD: props.riskHistory.after[riskKey].CVD / 100,
    riskAfterASCVD: props.riskHistory.after[riskKey].ASCVD / 100,
    riskAfterHeartFailure: props.riskHistory.after[riskKey].heartFailure / 100,
  };
  const riskValues = [
    riskBeforeCVD,
    riskBeforeASCVD,
    riskBeforeHeartFailure,
    riskAfterCVD,
    riskAfterASCVD,
    riskAfterHeartFailure,
  ];

  const maxRiskValue = Math.max(...riskValues, MAX_RISK_VALUE);
  const disabled = props.riskHistory.before['thirtyYearsRiskEstimate'].CVD === 0;

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {t('patientProfile.evaluation.risks.CKM.preventYearsEstimateOption')}
      </Typography>
      <TenYearsThirtyYearsSwitch
        tenYears={tenYears}
        setTenYears={setTenYears}
        translator={t}
        disabled={disabled}
      />
      <Box sx={{ mt: 5 }}>
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.CKM.CVD')}
          currentRisk={riskBeforeCVD}
          adjustedRisk={riskAfterCVD}
          maxRiskValue={maxRiskValue}
        />
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.CKM.ASCVD')}
          currentRisk={riskBeforeASCVD}
          adjustedRisk={riskAfterASCVD}
          maxRiskValue={maxRiskValue}
        />
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.CKM.heartFailure')}
          currentRisk={riskBeforeHeartFailure}
          adjustedRisk={riskAfterHeartFailure}
          maxRiskValue={maxRiskValue}
        />
      </Box>
    </Box>
  );
}

function TenYearsThirtyYearsSwitch({
  tenYears,
  setTenYears,
  translator,
  disabled,
}: {
  tenYears: boolean;
  setTenYears: (mostRecentLeft: boolean) => void;
  translator: (key: string) => string;
  disabled: boolean;
}) {
  const handleChange = (event: any) => {
    setTenYears(!event.target.checked);
  };
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography
          color={!disabled && tenYears ? 'primary.main' : 'neutral.main'}
          sx={{ margin: 0 }}
        >
          {translator('patientProfile.evaluation.risks.CKM.tenYears')}
        </Typography>
        {!disabled ? (
          <>
            <Switch
              checked={!tenYears}
              onChange={handleChange}
              size={'small'}
              sx={{
                margin: 0,
                '& .MuiSwitch-switchBase': {
                  color: 'secondary.dark',
                  '&.Mui-checked': {
                    color: 'secondary.dark',
                    '&:hover': {
                      backgroundColor: `primary.main`,
                    },
                    '& + .MuiSwitch-track': {
                      backgroundColor: 'secondary.dark',
                    },
                  },
                  '&:hover': {
                    backgroundColor: `primary.main`,
                  },
                },
                '& .MuiSwitch-thumb': {
                  color: 'primary.main',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: 'secondary.dark',
                },
              }}
            />
            <Typography color={tenYears ? 'neutral.main' : 'primary.main'} sx={{ margin: 0 }}>
              {translator('patientProfile.evaluation.risks.CKM.thirtyYears')}
            </Typography>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}
