import { useRef, useState } from 'react';
import { Grid, List, Stack } from '@mui/material';
import { GroupedRecommendations } from './GroupedRecommendations';
import { Recommendation } from '../../../../api/patients';
import { RecommendationCategorySelection } from './RecommendationCategorySelection';
import { RecommendationCategory } from '../../../../api/report';

interface RecommendationsProps {
  recommendations: Recommendation[];
  onChange: (recommendations: Recommendation[]) => any;
}

export default function Recommendations({ recommendations, onChange }: RecommendationsProps) {
  const refForSlidingRecommendations = useRef<null | HTMLUListElement>(null);
  const [chosenCategory, setChosenCategory] = useState<RecommendationCategory>(
    RecommendationCategory.recommendation,
  );

  const handleUserClick = (updatedRecommendation: Recommendation) => {
    onChange(
      recommendations.map((r) => (r.key === updatedRecommendation.key ? updatedRecommendation : r)),
    );
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <RecommendationCategorySelection currentValue={chosenCategory} onChange={setChosenCategory} />
      <Grid
        container
        justifyContent={'center'}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '35rem',
          borderWidth: '3px 0 3px 0',
          borderStyle: 'solid',
          borderColor: 'secondary.light',
          borderRadius: '9px',
        }}
      >
        <List ref={refForSlidingRecommendations}>
          <GroupedRecommendations
            recommendations={recommendations}
            chosenCategory={chosenCategory}
            onToggle={handleUserClick}
            refForSlidingRecommendations={refForSlidingRecommendations}
          />
        </List>
      </Grid>
    </Stack>
  );
}
