import { AssessmentFormType } from '../../AssessmentForm';
import Grid from '@mui/material/Grid';
import { Lab } from '../../../../../types/assessments';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { ruleHelper } from '../../logic/rule-helper';
import React from 'react';
import { allowedValueRangeForLab, LAB_UNITS, labParameterKeys } from './Lab.logic';
import { useActiveModules } from '../../../../patient-profile/WebheaderComponents/ActiveModuleComponents/useActiveModules';

export function LabEditable({ form }: { form: AssessmentFormType }) {
  return (
    <Grid container columnSpacing={3} rowSpacing={1} sx={{ p: 0 }}>
      {labParameterKeys.map((fieldName) => (
        <LabInput key={fieldName} fieldName={fieldName} form={form} />
      ))}
    </Grid>
  );
}

function LabInput({ fieldName, form }: { fieldName: keyof Lab; form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const { activeModules } = useActiveModules();
  const requiredFields: Array<keyof Lab> = activeModules.CKM_module
    ? ['totalCholesterol', 'HDLCholesterol', 'eGFR']
    : [];

  return (
    <Grid item xs={12} sm={6} md={12} sx={{ p: 0 }}>
      <StyledTextField
        name={fieldName}
        type="number"
        label={
          t(`lab.${fieldName}.label`) +
          (t(`lab.${fieldName}.tooltip`) ? ` (${t(`lab.${fieldName}.tooltip`)})` : '')
        }
        register={form.register(
          fieldName,
          ruleHelper(t, allowedValueRangeForLab, fieldName, requiredFields.includes(fieldName), {
            decimal: fieldName !== 'eGFR',
          }),
        )}
        errors={form.formState.errors}
        adornment={LAB_UNITS[fieldName]}
      />
    </Grid>
  );
}
