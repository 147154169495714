import { AssessmentParameters, CCS_SAF_Score } from '../../../../../types/assessments';
import { useTranslation } from 'react-i18next';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { AssessmentFormType } from '../../AssessmentForm';
import { CLINICAL_UNITS, clinicalParameterKeys } from './Clinical.logic';
import { NumericalParameterLine } from '../../subComponents/NumericalParameterLine';

export function ClinicalNonEditable({ form }: { form: AssessmentFormType }) {
  const values = form.getValues();
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <ClinicalInput values={values} />
    </div>
  );
}

function CCSSAFScoreInput({ score }: { score: CCS_SAF_Score }) {
  const { t } = useTranslation('forms');

  return (
    <Grid item xs={12} sx={{ mt: 2 }} alignItems={'left'}>
      <NumericalParameterLine
        title={t('clinical.CCS_SAF_score.label')}
        value={score}
        tooltip={t('clinical.CCS_SAF_score.tooltip')}
        unitLabel={`(${CCS_SAF_Score[score]})`}
        withDivider={false}
      />
    </Grid>
  );
}

function ClinicalInput({ values }: { values: AssessmentParameters }) {
  const { t } = useTranslation('forms');
  return (
    <>
      <Stack>
        {clinicalParameterKeys
          .filter((key) => key !== 'CCS_SAF_score')
          .map((fieldName) => (
            <NumericalParameterLine
              title={t(`clinical.${fieldName}.label`)}
              value={values[fieldName]}
              unitLabel={CLINICAL_UNITS[fieldName]}
              withDivider={true}
              tooltip={t(`clinical.${fieldName}.tooltip`, '')}
              key={fieldName}
            />
          ))}
      </Stack>
      <CCSSAFScoreInput score={values.CCS_SAF_score} />
    </>
  );
}
