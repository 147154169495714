import React, { useEffect, useMemo } from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import Recommendations from './recommendations/Recommendations';
import {
  EvaluationResult,
  EvaluationResultCKM,
  useAdjustedRisksOfAssessment,
  useAdjustedRisksOfAssessmentCKM,
  useEvaluationResultOfAssessment,
  useEvaluationResultOfAssessmentCKM,
} from '../../../api/patients';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/useSettings';
import { InformationTextPopup } from '../../core/InformationText/InformationTextPopup';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { LoadingIndicator } from '../../core/LoadingIndicator';
import { DownloadReportButton, SaveAndCloseButton } from './buttons';
import { AssessmentIdOrLatest } from '../../../api/report';
import {
  belongsToCorrectAssessment,
  StoredRecommendationConfiguration,
  useRecommendationsSessionStorage,
} from '../../../state/recommendations';
import { isSameRecommendation } from '../../../utils/recommendations';
import { Risks as RisksType } from '../../../types/risk';
import Risks from './risks/Risks';
import { isRedoxPage } from '../../../utils/isRedoxPage';
import { useActiveModules } from '../WebheaderComponents/ActiveModuleComponents/useActiveModules';
import RisksCKM from './risks/RisksCKM';

type SaveAndCloseFunction = (evalData: EvaluationResult) => void;

interface EvaluationProps {
  patientId: string;
  saveAndClose?: SaveAndCloseFunction;
  assessmentId?: AssessmentIdOrLatest;
}

const LEFT_COLUMN_WIDTH_TWO_RISKS = 3.5;
const RIGHT_COLUMN_WIDTH_TWO_RISKS = 5;
const LEFT_COLUMN_WIDTH_SINGLE_RISK = 5.2;
const RIGHT_COLUMN_WIDTH_SINGLE_RISK = 6.8;

export function Evaluation({ patientId, saveAndClose, assessmentId = 'latest' }: EvaluationProps) {
  const { helpers } = useSettings();
  const { t } = useTranslation('pages');
  const { evaluationData, recommendations, activeModules } = useEvaluationData(
    patientId,
    assessmentId,
  );
  const {
    savedRecommendationsConfig,
    setSavedRecommendationsConfig,
  } = useRecommendationsManagement(patientId, assessmentId, recommendations.allRecommendations);
  const evaluationResultAF = evaluationData.evaluationResultAF;
  const evaluationResultCKM = evaluationData.evaluationResultCKM;

  const {
    isRiskDataLoadingAF,
    isRiskDataLoadingCKM,
    adjustedRisksAF,
    adjustedRisksCKM,
  } = useRiskData(
    patientId,
    assessmentId,
    savedRecommendationsConfig,
    evaluationResultAF,
    evaluationResultCKM,
  );

  const numberOfActiveModules = getNumberOfActiveModules(activeModules);
  const left_column =
    numberOfActiveModules === 2 ? LEFT_COLUMN_WIDTH_TWO_RISKS : LEFT_COLUMN_WIDTH_SINGLE_RISK;
  const right_column =
    numberOfActiveModules === 2 ? RIGHT_COLUMN_WIDTH_TWO_RISKS : RIGHT_COLUMN_WIDTH_SINGLE_RISK;
  return (
    <Box sx={{ p: 0, width: '100%' }}>
      {evaluationResultAF && evaluationResultCKM && (
        <Paper elevation={1} sx={{ padding: [0, 8, 0, 8] }}>
          <Typography variant="body2" fontWeight="bold" sx={{ mb: 2 }}>
            {t('patientProfile.evaluation.dateLabel', {
              date: helpers.formatDate(evaluationResultAF.date),
            })}
          </Typography>
          <Grid container spacing={10}>
            {activeModules.CKM_module ? (
              evaluationResultCKM.currentRisks.tenYearsRiskEstimate ? (
                <Grid item xs={left_column}>
                  <RisksHeaderBlockCKM />
                  {isRiskDataLoadingCKM ? (
                    <LoadingIndicator relativeVerticalPosition={100} />
                  ) : (
                    <RisksCKM
                      riskHistory={{
                        before: evaluationResultCKM.currentRisks,
                        after: adjustedRisksCKM
                          ? adjustedRisksCKM
                          : evaluationResultCKM.adjustedRisks,
                      }}
                    />
                  )}
                </Grid>
              ) : (
                <Grid item xs={left_column}>
                  <RisksHeaderBlockCKM />
                  <PreventVariableOutsideOfValidatedRangeMessage
                    evaluationResultCKM={evaluationResultCKM}
                  />
                </Grid>
              )
            ) : null}
            {activeModules.AF_module ? (
              <Grid item xs={left_column}>
                <RisksHeaderBlock
                  cha2ds2vascScore={evaluationResultAF.currentRisks.cha2ds2vascScore}
                />
                {isRiskDataLoadingAF ? (
                  <LoadingIndicator relativeVerticalPosition={100} />
                ) : (
                  <Risks
                    riskHistory={{
                      before: evaluationResultAF.currentRisks,
                      after: adjustedRisksAF ? adjustedRisksAF : evaluationResultAF.adjustedRisks,
                    }}
                  />
                )}
              </Grid>
            ) : null}
            <Grid item xs={right_column}>
              <RecommendationHeaderBlock />
              <Recommendations
                recommendations={savedRecommendationsConfig.recommendations}
                onChange={(recommendations) =>
                  setSavedRecommendationsConfig({
                    assessment: assessmentId,
                    recommendations,
                    patientId: Number.parseInt(patientId),
                  })
                }
              />

              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ marginBottom: 6, marginTop: 1, px: '1.5rem' }}
              >
                <Grid item xs={6}>
                  <SaveAndCloseButton
                    onSaveAndClose={
                      saveAndClose ? () => saveAndClose(evaluationResultAF) : undefined
                    }
                  />
                </Grid>
                {!isRedoxPage() && (
                  <Grid item xs={6}>
                    <DownloadReportButton patientId={patientId} assessmentId={assessmentId} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
}

function RisksHeaderBlock({
  cha2ds2vascScore,
}: {
  cha2ds2vascScore: RisksType['cha2ds2vascScore'];
}) {
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
        {t('patientProfile.evaluation.risks.afDriven')}
      </Typography>
      <Divider />
      <Typography variant="h6" fontWeight="bold" sx={{ mt: 2 }}>
        {t('patientProfile.evaluation.risks.cha2ds2vasc', {
          score: cha2ds2vascScore,
        })}
      </Typography>
    </>
  );
}

function RecommendationHeaderBlock() {
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant="h3" fontWeight="bold" sx={{ mb: 1 }}>
        {t('patientProfile.evaluation.recommendations.heading')}
      </Typography>
      <Typography>
        {t('patientProfile.evaluation.recommendations.helperText')}
        <InformationTextPopup
          document={{
            title: t('patientProfile.evaluation.recommendations.detailedHelpTextTitle'),
            sections: [
              {
                paragraphs: [t('patientProfile.evaluation.recommendations.detailedHelpText')],
              },
            ],
          }}
        />
      </Typography>
    </>
  );
}

function RisksHeaderBlockCKM() {
  const { t } = useTranslation('pages');
  return (
    <>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>
        {t('patientProfile.evaluation.risks.CKM.header')}
      </Typography>
      <Divider />
    </>
  );
}

function getNumberOfActiveModules(activeModules: {
  AF_module: boolean;
  CKM_module: boolean;
  [key: string]: boolean;
}): number {
  return Object.values(activeModules).filter(Boolean).length;
}

function useEvaluationData(patientId: string, assessmentId: AssessmentIdOrLatest) {
  const { activeModules, activeModulesSetter } = useActiveModules();

  const { data: evaluationResultAF, isLoading: isLoadingAF } = useWithLoadingAndErrorIndication(
    useEvaluationResultOfAssessment(patientId, assessmentId.toString()),
  );

  const { data: evaluationResultCKM, isLoading: isLoadingCKM } = useWithLoadingAndErrorIndication(
    useEvaluationResultOfAssessmentCKM(patientId, assessmentId.toString()),
  );

  // Initialize CKM module based on data
  useEffect(() => {
    if (!isLoadingCKM && evaluationResultCKM) {
      activeModulesSetter.setCKM_module(
        evaluationResultCKM?.recommendations && evaluationResultCKM.recommendations.length > 0,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCKM, evaluationResultCKM]);

  // Combine recommendations from active modules
  const allRecommendations = useMemo(() => {
    if (isLoadingAF || isLoadingCKM) {
      return [];
    }

    return [
      ...(activeModules.AF_module ? evaluationResultAF?.recommendations ?? [] : []),
      ...(activeModules.CKM_module ? evaluationResultCKM?.recommendations ?? [] : []),
    ];
  }, [
    evaluationResultAF,
    evaluationResultCKM,
    isLoadingAF,
    isLoadingCKM,
    activeModules.AF_module,
    activeModules.CKM_module,
  ]);

  return {
    evaluationData: {
      evaluationResultAF,
      evaluationResultCKM,
      isLoadingAF,
      isLoadingCKM,
    },
    recommendations: {
      allRecommendations,
    },
    activeModules,
  };
}

function useRecommendationsManagement(
  patientId: string,
  assessmentId: AssessmentIdOrLatest,
  allRecommendations: any[],
) {
  const [
    savedRecommendationsConfig,
    setSavedRecommendationsConfig,
  ] = useRecommendationsSessionStorage(
    {
      assessment: assessmentId,
      patientId: Number.parseInt(patientId),
      recommendations: allRecommendations,
    },
    true,
  );

  // Update saved recommendations when data changes
  useEffect(() => {
    if (allRecommendations.length > 0) {
      setSavedRecommendationsConfig({
        assessment: assessmentId,
        patientId: Number.parseInt(patientId),
        recommendations: !belongsToCorrectAssessment(savedRecommendationsConfig, {
          assessment: assessmentId,
          patientId: Number.parseInt(patientId),
        })
          ? allRecommendations
          : allRecommendations.map((re) => {
              // preserve "applied" ticks
              const oldRecommendation = savedRecommendationsConfig.recommendations.find((oldRe) =>
                isSameRecommendation(oldRe, re),
              );
              return oldRecommendation ?? re;
            }),
      });
    }
    // do not add savedRecommendationsConfig
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, assessmentId, allRecommendations, setSavedRecommendationsConfig]);

  return { savedRecommendationsConfig, setSavedRecommendationsConfig };
}

function useRiskData(
  patientId: string,
  assessmentId: AssessmentIdOrLatest,
  savedRecommendationsConfig: StoredRecommendationConfiguration,
  evaluationResult: EvaluationResult | undefined,
  evaluationResultCKM: EvaluationResultCKM | undefined,
) {
  // Get adjusted risks for AF
  const {
    data: adjustedRisksAF,
    isLoading: isLoadingAdjustedRisksAF,
  } = useAdjustedRisksOfAssessment(
    patientId,
    assessmentId.toString(),
    savedRecommendationsConfig.recommendations,
    Boolean(evaluationResult),
  );

  // Get adjusted risks for CKM
  const {
    data: adjustedRisksCKM,
    isLoading: isLoadingAdjustedRisksCKM,
  } = useAdjustedRisksOfAssessmentCKM(
    patientId,
    assessmentId.toString(),
    savedRecommendationsConfig.recommendations,
    Boolean(evaluationResultCKM),
  );

  return {
    isRiskDataLoadingAF: isLoadingAdjustedRisksAF,
    isRiskDataLoadingCKM: isLoadingAdjustedRisksCKM,
    adjustedRisksAF: adjustedRisksAF,
    adjustedRisksCKM: adjustedRisksCKM,
  };
}

function PreventVariableOutsideOfValidatedRangeMessage({
  evaluationResultCKM,
}: {
  evaluationResultCKM: EvaluationResultCKM | undefined;
}) {
  const { t } = useTranslation('pages');
  const CKM_moduleApplicable =
    evaluationResultCKM?.recommendations && evaluationResultCKM.recommendations.length > 0;
  const hasApiError = evaluationResultCKM?.currentRisks?.error !== undefined;
  return hasApiError ? (
    <Typography>{t('patientProfile.evaluation.risks.CKM.apiError')}</Typography>
  ) : (
    <Typography>
      {CKM_moduleApplicable
        ? t('patientProfile.evaluation.risks.CKM.PreventVariableOutsideValidatedRange')
        : t('patientProfile.evaluation.risks.CKM.CKMNotApplicable')}
    </Typography>
  );
}
