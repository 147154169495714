import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';
import { AssessmentParameters, Imaging } from '../../../../../types/assessments';
import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { MinMaxType } from '../../logic/rule-helper';

export const IMAGING_UNITS = {
  CAC: '',
  LVH: 'g/m²',
  LADiameter: 'mm',
  LFEjectionFraction: '%',
};

const imagingDataRepresentationMapping: RepresentationMapping<Imaging> = {
  CAC: DataRepresentations.other,
  LVH: DataRepresentations.other,
  LADiameter: DataRepresentations.other,
  LFEjectionFraction: DataRepresentations.other,
};

export const imagingParameterKeys = getAllKeys(imagingDataRepresentationMapping);

export function resetImaging(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      imagingParameterKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}

export const allowedValueRangeForImaging: MinMaxType<keyof Imaging> = {
  CAC: { min: 0, max: 1000 },
  LVH: { min: 1, max: 250 },
  LADiameter: {
    min: 10,
    max: 70,
  },
  LFEjectionFraction: {
    min: 0,
    max: 100,
  },
};
