import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';
import { AssessmentParameters, Lab } from '../../../../../types/assessments';
import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { MinMaxType } from '../../logic/rule-helper';

export const LAB_UNITS = {
  UACR: 'mg/g',
  AER: 'mg/24hrs',
  HBA1c: '%',
  fastingBloodGlucose: 'mg/dL',
  triglycerides: 'mg/dL',
  totalCholesterol: 'mg/dL',
  LDLCholesterol: 'mg/dL',
  HDLCholesterol: 'mg/dL',
  NTproBNP: 'pg/mL',
  hsTroponinT: 'ng/L',
  hsTroponinI: 'ng/L',
  eGFR: 'ml/min/1.73m²',
};

const labDataRepresentationMapping: RepresentationMapping<Lab> = {
  UACR: DataRepresentations.other,
  AER: DataRepresentations.other,
  HBA1c: DataRepresentations.other,
  fastingBloodGlucose: DataRepresentations.other,
  triglycerides: DataRepresentations.other,
  totalCholesterol: DataRepresentations.other,
  LDLCholesterol: DataRepresentations.other,
  HDLCholesterol: DataRepresentations.other,
  NTproBNP: DataRepresentations.other,
  hsTroponinT: DataRepresentations.other,
  hsTroponinI: DataRepresentations.other,
  eGFR: DataRepresentations.other,
};

export const labParameterKeys = getAllKeys(labDataRepresentationMapping);

export function resetLab(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      labParameterKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}

export const allowedValueRangeForLab: MinMaxType<keyof Lab> = {
  UACR: { min: 0, max: 25000 },
  AER: { min: 0, max: 3000 },
  HBA1c: { min: 3, max: 15 },
  fastingBloodGlucose: { min: 20, max: 1500 },
  triglycerides: { min: 0, max: 2000 },
  totalCholesterol: { min: 100, max: 400 },
  LDLCholesterol: { min: 50, max: 400 },
  HDLCholesterol: { min: 10, max: 150 },
  NTproBNP: { min: 0, max: 10000 },
  hsTroponinT: { min: 0, max: 100 },
  hsTroponinI: { min: 0, max: 100 },
  eGFR: { min: 0, max: 150 },
};
