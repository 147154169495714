import { Checkbox, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import { useActiveModules } from './useActiveModules';

export function ActiveModulesCheckboxes() {
  const theme = useTheme();
  const { activeModules, activeModulesSetter } = useActiveModules();
  const AF_module = activeModules.AF_module;
  const CKM_module = activeModules.CKM_module;

  const setCKM_module = activeModulesSetter.setCKM_module;
  const setAF_module = activeModulesSetter.setAF_module;

  const handleAFChange = (checked: boolean) => {
    // Only allow unchecking if CKM is checked
    if (checked || CKM_module) {
      setAF_module(checked);
    }
  };

  const handleCKMChange = (checked: boolean) => {
    // Only allow unchecking if AF is checked
    if (checked || AF_module) {
      setCKM_module(checked);
    }
  };

  return (
    <Stack direction="row" spacing={1} sx={{ pl: 5, transform: 'translateY(8px)' }}>
      <Typography sx={{ color: theme.palette.neutral.main }} variant="h6" display="inline">
        {'Active Modules:'}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={AF_module}
            onChange={(e) => handleAFChange(e.target.checked)}
            sx={{ transform: 'translateY(-8px)' }}
          />
        }
        label={
          <Typography sx={{ color: theme.palette.neutral.main }} variant="h6" display="inline">
            {'AF'}
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={CKM_module}
            onChange={(e) => handleCKMChange(e.target.checked)}
            sx={{ transform: 'translateY(-8px)' }}
          />
        }
        label={
          <Typography sx={{ color: theme.palette.neutral.main }} variant="h6" display="inline">
            {'CKM'}
          </Typography>
        }
      />
    </Stack>
  );
}
