import { AssessmentFormType } from '../../../AssessmentForm';
import { MedicationKeys, medicationKeysBooleanValues } from '../medications.logic';
import {
  AntiArrhythmicMedication_Class_1or3,
  ARNiDosage,
  ContraindicationForOAC,
  DiabetesMedication,
  Medication,
  OralAnticoagulant,
  Statin,
} from '../../../../../../types/assessments';

export interface GroupedMedicationProps {
  form: AssessmentFormType;
  medicationKeys: MedicationKeys[];
  columnCount?: number;
  headline: string;
  showTitle?: boolean;
}

function isBooleanKey(key: keyof Medication): boolean {
  return medicationKeysBooleanValues.includes(key);
}

export function separateKeys(medicationKeys: MedicationKeys[]) {
  const [booleanKeys, enumKeys] = [
    medicationKeys.filter((key) => isBooleanKey(key)),
    medicationKeys.filter((key) => !isBooleanKey(key)),
  ];
  return { booleanKeys, enumKeys };
}

export function keyEnumMapper(medicationKey: MedicationKeys) {
  switch (medicationKey) {
    case 'ARNi':
      return ARNiDosage;
    case 'oralAnticoagulant':
      return OralAnticoagulant;
    case 'diabetesMedication':
      return DiabetesMedication;
    case 'statin':
      return Statin;
    case 'contraindicationForOAC':
      return ContraindicationForOAC;
    case 'antiArrhythmicMedication_Class1or3':
      return AntiArrhythmicMedication_Class_1or3;
  }
}
