export enum OralAnticoagulant {
  NONE = 'none',
  NOAC = 'noac',
  VKA = 'vka',
}

export enum DiabetesMedication {
  NONE = 'none',
  PROVEN_GLP1RA = 'proven_glp1ra',
  OTHER_GLP1RA = 'other_glp1ra',
  SGLT2 = 'sglt2',
}

export enum CCS_SAF_Score {
  Asymptomatic = 0,
  Minimal = 1,
  Minor = 2,
  Moderate = 3,
  Severe = 4,
}

export enum AntiArrhythmicMedication_Class_1or3 {
  NotTried = 'not_tried',
  TriedInPast = 'tried_in_past',
  CurrentlyOnIt = 'currently_on_it',
}

export enum StatinDosage {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum Statin {
  None = 'None',
  Atorvastatin = 'Atorvastatin',
  Fluvastatin = 'Fluvastatin',
  Lovastatin = 'Lovastatin',
  Pitavastatin = 'Pitavastatin',
  Pravastatin = 'Pravastatin',
  Rosuvastatin = 'Rosuvastatin',
  Simvastatin = 'Simvastatin',
}

interface StatinDosageRange {
  low?: string;
  medium?: string;
  high?: string;
}

export const statinDosagesPerMedication: { [key in Statin]: StatinDosageRange } = {
  [Statin.None]: {},
  [Statin.Atorvastatin]: { medium: '10 to 20 mg', high: '40 to 80 mg' },
  [Statin.Fluvastatin]: { low: '20 to 40 mg', medium: '40 mg 2x/day; XL 80 mg' },
  [Statin.Lovastatin]: { low: '20 mg', medium: '40 to 80 mg' },
  [Statin.Pitavastatin]: { medium: '1 to 4 mg' },
  [Statin.Pravastatin]: { low: '10 to 20 mg', medium: '40 to 80 mg' },
  [Statin.Rosuvastatin]: { medium: '5 to 10 mg', high: '20 to 40 mg' },
  [Statin.Simvastatin]: { low: '10 mg', medium: '20 to 40 mg' },
};

export enum ARNiDosage {
  None = 'None',
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum ContraindicationForOAC {
  None = 'none',
  LongTerm = 'long_term',
  Permanent = 'permanent',
}

export type Vitals = {
  weight: number | null;
  height: number | null;
  pulse: number | null;
  systolicBloodPressure: number;
  diastolicBloodPressure: number | null;
};

export type MedicalHistory = {
  atrialFibrillation: boolean;
  coronaryArteryDisease: boolean;
  heartFailure: boolean;
  carotidArteryDisease: boolean;
  peripheralArteryDisease: boolean;
  historyOfStroke: boolean;
  transientIschemicAttack: boolean;
  thromboembolism: boolean;
  historyOfBleeding: boolean;
  chronicKidneyDisease: boolean | null;
  diabetes: boolean;
  hypertension: boolean;
  currentSmoker: boolean | null;
  dementia: boolean;
  HFrEF: boolean;
  HFpEF: boolean;
};

export type Treatment = {
  rhythmControlDesired: boolean;
};

export type Medication = {
  antiArrhythmicMedication_Class1or3: AntiArrhythmicMedication_Class_1or3;
  contraindicationForOAC: ContraindicationForOAC;
  antiplateletMedication: boolean;
  oralAnticoagulant: OralAnticoagulant;
  diabetesMedication: DiabetesMedication;
  statin: Statin;
  statinDosage: StatinDosage | null;
  metformin: boolean;
  EPA: boolean;
  ACE: boolean;
  ARB: boolean;
  ARNi: ARNiDosage;
  ezetimibe: boolean;
  ASS: boolean;
  P2Yi: boolean;
  PCSK9i: boolean;
  betablocker: boolean;
  steroidalMRA: boolean;
  CCB: boolean;
  DPP4i: boolean;
  TZD: boolean;
  hydralazine_isosorbide: boolean;
  finerenone: boolean;
  other_GLP1RA: boolean;
  proven_GLP1RA: boolean;
  SGLT2i: boolean;
};

export type Lab = {
  UACR: number | null;
  AER: number | null;
  eGFR: number | null;
  HBA1c: number | null;
  fastingBloodGlucose: number | null;
  triglycerides: number | null;
  totalCholesterol: number | null;
  LDLCholesterol: number | null;
  HDLCholesterol: number | null;
  NTproBNP: number | null;
  hsTroponinT: number | null;
  hsTroponinI: number | null;
};

export type Imaging = {
  CAC: number | null;
  LVH: number | null;
  LFEjectionFraction: number | null;
  LADiameter: number | null;
};

export type Clinical = {
  waistCircumference: number | null;
  adverseSDOH: string | null;
  CCS_SAF_score: CCS_SAF_Score;
};

export interface AssessmentParameters
  extends Vitals,
    MedicalHistory,
    Treatment,
    Medication,
    Lab,
    Imaging,
    Clinical {
  date?: string;
}

export interface Assessment extends AssessmentParameters {
  id: number;
  patientId: number;
  createdAt: Date;
}

export interface AssessmentDifference
  extends VitalDifference,
    OptionalParameterDifference,
    MedicalHistoryDifference,
    MedicationDifference,
    TreatmentDifference {
  [key: string]: any;
}

export interface VitalDifference {
  bmi?: number;
  pulse?: number;
  systolicBloodPressure?: number;
  diastolicBloodPressure?: number;
}

export interface MedicalHistoryDifference {
  CCS_SAF_score?: number;
  atrialFibrillation?: boolean;
  coronaryArteryDisease?: boolean;
  heartFailure?: boolean;
  carotidArteryDisease?: boolean;
  peripheralArteryDisease?: boolean;
  historyOfStroke?: boolean;
  transientIschemicAttack?: boolean;
  thromboembolism?: boolean;
  historyOfBleeding?: boolean;
  chronicKidneyDisease?: boolean | null;
  diabetes?: boolean;
  hypertension?: boolean;
  currentSmoker?: boolean | null;
  dementia?: boolean;
}

export interface MedicationDifference {
  antiArrhythmicMedication_Class1or3?: AntiArrhythmicMedication_Class_1or3;
  contraindicationForOAC?: ContraindicationForOAC;
  antiplateletMedication?: boolean;
  oralAnticoagulant?: OralAnticoagulant;
  diabetesMedication?: DiabetesMedication;
}

export interface OptionalParameterDifference {
  LFEjectionFraction?: number;
  LADiameter?: number;
  eGFR?: number;
}

export interface TreatmentDifference {
  rhythmControlDesired?: boolean;
}

export interface ImperialVitals extends Omit<Vitals, 'height'> {
  feet: number | null;
  inches: number | null;
}

export interface ImperialAssessmentParameters
  extends Omit<AssessmentParameters, keyof Vitals>,
    ImperialVitals {}
