import { useContext } from 'react';
import { ActiveModuleContext } from './ActiveModulesProvider';

export function useActiveModules() {
  const { activeModules, activeModulesSetter } = useContext(ActiveModuleContext);
  return {
    activeModules,
    activeModulesSetter,
  };
}
