import { Patient } from '../../../../api/patients';
import { useNavigateToEvaluation } from '../../../../config/urlPaths';
import { useWithLoadingIndication } from '../../../../hooks/loadingIndication/useWithLoadingIndication';
import { assessmentsApi } from '../../../../api/assessments';
import { AssessmentForm } from '../../assessmentForm/AssessmentForm';
import React from 'react';
import { scrollToTop } from '../../../../utils/general';
import { AssessmentParameters } from '../../../../types/assessments';
import { AssessmentIdOrLatest } from '../../../../api/report';
import { useSettings } from '../../../../hooks/useSettings';
import { AssessmentCardEditModeProps } from '../../assessmentForm/subComponents/AssessmentCard/AssessmentCard';

interface WebAssessmentProps {
  patient: Patient;
  patientId: string;
  prefilledValues?: Partial<AssessmentParameters>;
  editModeProps: AssessmentCardEditModeProps;
}

export function WebAssessment(props: WebAssessmentProps) {
  const assessmentId: AssessmentIdOrLatest = 'latest';
  const { helpers } = useSettings();
  const goToRiskPage = useNavigateToEvaluation();

  const onCreateAssessment = useWithLoadingIndication(async (params: AssessmentParameters) => {
    await assessmentsApi.create(
      params,
      props.patient.id,
      helpers.formattedDateStringToDate(params.date),
    );
    scrollToTop();
    goToRiskPage(props.patientId, assessmentId);
  });

  return (
    <AssessmentForm
      onSubmit={onCreateAssessment}
      editModeProps={props.editModeProps}
      prefilledValues={props.prefilledValues}
    />
  );
}
