import { NonEditableAssessmentListItem } from '../../../subComponents/NonEditableAssessmentListItem';
import { useTranslation } from 'react-i18next';
import { Box, List, Stack, Typography } from '@mui/material';
import { DropdownNonEditable } from '../../../subComponents/DropdownNonEditable';
import { GroupedMedicationProps, separateKeys } from './groupedMedication';

export function GroupedMedicationsNonEditable({
  form,
  medicationKeys,
  headline,
  columnCount,
  showTitle,
}: GroupedMedicationProps) {
  const { t } = useTranslation('forms');
  const values = form.getValues();
  const { booleanKeys, enumKeys } = separateKeys(medicationKeys);

  return (
    <>
      <Typography variant={'h6'}>{headline}</Typography>
      {booleanKeys.length > 0 ? (
        <List
          disablePadding={true}
          sx={{ columnCount: columnCount === undefined ? 2 : columnCount, pb: 0, pl: 2 }}
          dense
        >
          <Stack direction={'column'}>
            {booleanKeys.map((key) => (
              <NonEditableAssessmentListItem
                label={t(`medications.${key}.label`)}
                withDivider={false}
                isChecked={values[key]}
                tooltip={t(`medications.${key}.tooltip`)}
                key={key}
              />
            ))}
          </Stack>
        </List>
      ) : null}
      {enumKeys.map((key) => (
        <Box mt={2} pl={3} pb={1} key={key}>
          <DropdownNonEditable
            title={t(`medications.${key}.label`)}
            value={t(`medications.${key}.values.${values[key]}.label`).toString()}
            withDivider={false}
            tooltip={t(`medications.${key}.tooltip`)}
            textAlign={'left'}
            showTitle={showTitle}
          />
        </Box>
      ))}
    </>
  );
}
