import { AssessmentFormType } from '../../AssessmentForm';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { NumericalParameterLine } from '../../subComponents/NumericalParameterLine';
import React from 'react';
import { IMAGING_UNITS, imagingParameterKeys } from './Imaging.logic';

export function ImagingNonEditable({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');
  const values = form.getValues();

  return (
    <Stack>
      {imagingParameterKeys.map((fieldName, index, keys) => (
        <NumericalParameterLine
          title={t(`imaging.${fieldName}.label`)}
          value={values[fieldName]}
          unitLabel={IMAGING_UNITS[fieldName]}
          withDivider={index !== keys.length - 1}
          tooltip={t(`imaging.${fieldName}.tooltip`)}
          key={keys[index]}
        />
      ))}
    </Stack>
  );
}
