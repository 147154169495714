import { Box, Divider, Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioOption } from '../../../../core/input-components/StyledRadioGroup';
import { AssessmentFormType } from '../../AssessmentForm';
import { Statin, statinDosagesPerMedication } from '../../../../../types/assessments';
import {
  antiArrhythmicMedication_Class1or3_keys,
  antidiabeticsKeys,
  antihypertensivesKeys,
  antiplateletMedicationKeys,
  contraindicationForOAC_keys,
  heartfailureMedicationKeys,
  lipidLoweringAgentKeys,
  oralAnticoagulantKeys,
} from './medications.logic';
import { GroupedMedicationsEditable } from './components/GroupedMedicationsEditable';
import { DropdownEditable } from '../../subComponents/DropdownEditable';

export function MedicationsEditable({
  form,
  chosenStatinOption,
}: {
  form: AssessmentFormType;
  chosenStatinOption: Statin;
}) {
  const { t } = useTranslation('forms');
  const required = t('errors.required');

  if (chosenStatinOption === Statin.None) {
    form.setValue('statinDosage', null);
  } else {
    form.register('statinDosage', { required: required });
  }

  const statinRadioOptions = Object.entries(statinDosagesPerMedication[chosenStatinOption]).map(
    ([dosage, value]) => ({
      label: value,
      value: dosage,
      tooltip: t(`medications.statinDosage.values.${dosage}.tooltip`),
    }),
  ) as RadioOption[];

  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Divider sx={{ mb: 1 }} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={antihypertensivesKeys}
            headline={'Antihypertensives'}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={antidiabeticsKeys}
            headline={'Antidiabetics'}
            columnCount={2}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={antiplateletMedicationKeys}
            headline={'Antiplatelet Medication'}
            columnCount={2}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={heartfailureMedicationKeys}
            headline={'Heart failure medication'}
            columnCount={1}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>

        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={lipidLoweringAgentKeys}
            headline={'Lipid Lowering agent'}
            columnCount={1}
          />
          {chosenStatinOption !== Statin.None ? (
            <Box mt={2} pl={3} pb={1}>
              <DropdownEditable
                isColumn={true}
                name={'statinDosage'}
                label={t('medications.statinDosage.label')}
                options={statinRadioOptions}
                control={form.control}
                errors={form.formState.errors}
              />
            </Box>
          ) : null}
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={oralAnticoagulantKeys}
            headline={'Oral anticoagulant'}
            showTitle={false}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={antiArrhythmicMedication_Class1or3_keys}
            headline={'Antiarrhythmics (Class I or III)'}
            showTitle={false}
          />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12}>
          <GroupedMedicationsEditable
            form={form}
            medicationKeys={contraindicationForOAC_keys}
            headline={'Contraindication for OAC'}
            showTitle={false}
          />
        </Grid>
      </Grid>
    </div>
  );
}
