import {
  DataRepresentations,
  getAllKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';
import { AssessmentParameters, Clinical } from '../../../../../types/assessments';
import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { MinMaxType } from '../../logic/rule-helper';

export const CLINICAL_UNITS = {
  waistCircumference: 'cm',
  adverseSDOH: '',
  CCS_SAF_score: '',
};

const clinicalDataRepresentationMapping: RepresentationMapping<Clinical> = {
  waistCircumference: DataRepresentations.other,
  adverseSDOH: DataRepresentations.other,
  CCS_SAF_score: DataRepresentations.other,
};

export const clinicalParameterKeys = getAllKeys(clinicalDataRepresentationMapping);

export function resetClinical(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      clinicalParameterKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}

export const allowedValueRangeForClinical: MinMaxType<keyof Clinical> = {
  waistCircumference: { min: 20, max: 200 },
  adverseSDOH: { min: 0, max: 99999 },
  CCS_SAF_score: { min: 0, max: 4 },
};
