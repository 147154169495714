import { AssessmentFormType } from '../../AssessmentForm';
import Grid from '@mui/material/Grid';
import React from 'react';
import { allowedValueRangeForImaging, IMAGING_UNITS, imagingParameterKeys } from './Imaging.logic';
import { Imaging } from '../../../../../types/assessments';
import { useTranslation } from 'react-i18next';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { ruleHelper } from '../../logic/rule-helper';

export function ImagingEditable({ form }: { form: AssessmentFormType }) {
  return (
    <Grid container columnSpacing={3} rowSpacing={1} sx={{ p: 0 }}>
      {imagingParameterKeys.map((fieldName) => (
        <ImagingInput key={fieldName} fieldName={fieldName} form={form} />
      ))}
    </Grid>
  );
}

function ImagingInput({ fieldName, form }: { fieldName: keyof Imaging; form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <Grid item xs={12} sm={6} md={12} sx={{ p: 0 }}>
      <StyledTextField
        name={fieldName}
        type="number"
        label={t(`imaging.${fieldName}.label`) + ` (${t(`imaging.${fieldName}.tooltip`)})`}
        register={form.register(fieldName, ruleHelper(t, allowedValueRangeForImaging, fieldName))}
        errors={form.formState.errors}
        adornment={IMAGING_UNITS[fieldName]}
      />
    </Grid>
  );
}
