import Grid from '@mui/material/Grid';
import { Divider, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LabelWithTooltip from '../../../core/input-components/LabelWithTooltip/LabelWithTooltip';

export function DropdownNonEditable({
  title,
  value,
  withDivider = true,
  tooltip,
  textAlign = 'center',
  showTitle = true,
}: {
  title: string;
  value: string | number | null | undefined | boolean;
  withDivider?: boolean;
  tooltip?: string;
  textAlign?: 'center' | 'left';
  showTitle?: boolean;
}) {
  const { t } = useTranslation('pages');
  const getValue = () => {
    if (typeof value === 'boolean') {
      return value ? t('general.yes') : t('general.no');
    }
    return value ?? '--';
  };
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={6} justifyContent={'center'}>
          {showTitle ? (
            tooltip && tooltip !== '' ? (
              <Stack direction={'row'}>
                <LabelWithTooltip label={<LineTitleLabel text={title} />} tooltipText={tooltip} />
              </Stack>
            ) : (
              <LineTitleLabel text={title} />
            )
          ) : null}
          <FormControl>
            <Select
              labelId={'select'}
              disabled
              value="Selected Value"
              sx={{ minWidth: '30rem', height: '2.5rem' }}
            >
              <MenuItem value="Selected Value">
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    m: 0,
                    p: 0,
                    textAlign: textAlign,
                  }}
                >
                  {getValue()}
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {withDivider && <Divider sx={{ mb: 1, mt: 1, p: 0 }} />}
    </>
  );
}

export function LineTitleLabel({ text }: { text: string }) {
  return (
    <Typography variant={'h6'} sx={{ m: 0, p: 0, fontWeight: 'lighter' }}>
      {text}
    </Typography>
  );
}
