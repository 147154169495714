import { Container } from '@mui/material';
import { WebHeader } from './WebheaderComponents/WebHeader';
import { Evaluation } from './evaluation/Evaluation';
import { parseAssessmentId } from './utils';
import React from 'react';
import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { usePatientById } from '../../api/patients';
import { ActiveModulesProvider } from './WebheaderComponents/ActiveModuleComponents/ActiveModulesProvider';

interface EvaluationParams {
  patientId: string;
  assessmentId: string;
}

export function WebEvaluationProfile() {
  const { patientId, assessmentId } = useParams() as EvaluationParams;
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (!patient) {
    return <div />;
  }

  return (
    <Container maxWidth="xl" sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
      {
        <>
          <ActiveModulesProvider>
            <WebHeader
              patient={patient}
              showAssessmentButton
              showEvaluationButton
              showDateDropdown
              showActiveModules
              assessmentId={parseAssessmentId(assessmentId)}
            />
            <Evaluation patientId={patientId} assessmentId={parseAssessmentId(assessmentId)} />
          </ActiveModulesProvider>
        </>
      }
    </Container>
  );
}
