import React, { useCallback, useEffect, useState } from 'react';

import MedicalHistoryEditable from './formPartials/medicalHistory/MedicalHistory.editable';
import { MedicationsEditable } from './formPartials/medications/Medications.editable';
import { ConsentSubmit } from './formPartials/confirmation/ConsentSubmit';
import { useResetVitals, vitalKeys } from './formPartials/vitals/vitals.logic';
import { TreatmentEditable } from './formPartials/treatment/Treatment.editable';
import AssessmentCard, {
  AssessmentCardEditModeProps,
} from './subComponents/AssessmentCard/AssessmentCard';
import { useTranslation } from 'react-i18next';
import { VitalsEditable } from './formPartials/vitals/Vitals.editable';
import { VitalsNonEditable } from './formPartials/vitals/Vitals.nonEditable';
import { useCorrectFormFieldsForSettings } from './logic/correctUnits';
import { useForm, UseFormReturn } from 'react-hook-form';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../config/defaultValues';
import MedicalHistoryNonEditable from './formPartials/medicalHistory/MedicalHistory.nonEditable';
import {
  medicalHistoryKeys,
  resetMedicalHistory,
} from './formPartials/medicalHistory/medicalHistory.logic';
import MedicationsNonEditable from './formPartials/medications/Medications.nonEditable';
import { medicationKeys, resetMedication } from './formPartials/medications/medications.logic';
import TreatmentNonEditable from './formPartials/treatment/Treatment.nonEditable';
import { resetTreatment, treatmentKeys } from './formPartials/treatment/treatment.logic';
import { Grid } from '@mui/material';
import { AssessmentParameters, ImperialAssessmentParameters } from '../../../types/assessments';
import { DateEditable } from './formPartials/date/DateEditable';
import { DateNonEditable } from './formPartials/date/DateNonEditable';
import { useSettings } from '../../../hooks/useSettings';
import { DevMode } from '../../../context/SettingsProvider';
import { LabEditable } from './formPartials/lab/Lab.editable';
import { LabNonEditable } from './formPartials/lab/Lab.nonEditable';
import { labParameterKeys, resetLab } from './formPartials/lab/Lab.logic';
import { ImagingNonEditable } from './formPartials/imaging/Imaging.nonEditable';
import { ImagingEditable } from './formPartials/imaging/Imaging.editable';
import { imagingParameterKeys, resetImaging } from './formPartials/imaging/Imaging.logic';
import { clinicalParameterKeys, resetClinical } from './formPartials/clinical/Clinical.logic';
import { ClinicalNonEditable } from './formPartials/clinical/Clinical.nonEditable';
import { ClinicalEditable } from './formPartials/clinical/Clinical.editable';
import { ErrorDialogue } from './subComponents/AssessmentCard/ErrorDialogue';

interface AssessmentFormProps {
  onSubmit?: (params: AssessmentParameters) => void;
  prefilledValues?: Partial<AssessmentParameters>;
  editModeProps?: AssessmentCardEditModeProps;
  editable?: boolean;
}

export type FormParameter = AssessmentParameters & ImperialAssessmentParameters;
export type AssessmentFormType = UseFormReturn<FormParameter>;

export function AssessmentForm({
  prefilledValues,
  onSubmit,
  editModeProps,
  editable = true,
}: AssessmentFormProps) {
  const form: AssessmentFormType = useForm<FormParameter>({
    mode: 'onBlur',
    defaultValues: { ...DEFAULT_ASSESSMENT_VALUES_METRIC, ...prefilledValues },
  });

  const { t } = useTranslation(['pages', 'forms']);
  const { settings } = useSettings();
  const resetVitals = useResetVitals(form, prefilledValues);

  useCorrectFormFieldsForSettings(form);

  const showHeartFailureSubparameters = form.watch('heartFailure');
  const statin = form.watch('statin');

  useEffect(() => {
    if (!showHeartFailureSubparameters) {
      form.setValue('HFrEF', DEFAULT_ASSESSMENT_VALUES_METRIC.HFrEF);
      form.setValue('HFpEF', DEFAULT_ASSESSMENT_VALUES_METRIC.HFpEF);
    }
  }, [form, showHeartFailureSubparameters]);

  const errors = form.formState.errors;
  const { setEditMode, onSaveEditTrigger, setOnSaveEditTrigger } = editModeProps || {};
  const [open, setOpenErrorDialogue] = useState(false);
  postprocessForAntiplateletMedication(form);

  const saveEdit = useCallback(() => {
    form.trigger().then(() => {
      const hasAnError = Object.keys(errors).length > 0;
      if (!hasAnError && setEditMode) {
        setEditMode(false);
      } else {
        setOpenErrorDialogue(true);
      }
    });
  }, [form, errors, setEditMode]);

  useEffect(() => {
    if (onSaveEditTrigger && setOnSaveEditTrigger) {
      saveEdit();
      setOnSaveEditTrigger(false);
    }
  }, [onSaveEditTrigger, setOnSaveEditTrigger, saveEdit]);

  useEffect(() => {
    if (Object.keys(errors).length > 0 && setEditMode) {
      setEditMode(true);
    }
  }, [setEditMode, errors]);

  return (
    <>
      <Grid container sx={{ width: '100%', pb: '1rem', pr: '1rem' }} spacing={2}>
        {settings.devMode === DevMode.Enabled && (
          <Grid item sm={12} sx={{ width: '100%' }}>
            <AssessmentCard
              title={'Date'}
              notEditableChildren={<DateNonEditable form={form} />}
              editableChildren={<DateEditable form={form} />}
              onAbortEdit={() => {}}
              form={form}
              inputValues={['date']}
              sx={{ height: '100%', width: '100%', pb: 0 }}
              editModeProps={editModeProps}
              editable={editable}
            />
          </Grid>
        )}
        <Grid
          item
          sm={12}
          md={5}
          sx={{
            flexDirection: 'column',
            display: 'flex',
          }}
        >
          <AssessmentCard
            title={t('pages:assessment.headings.vitals')}
            editableChildren={<VitalsEditable form={form} />}
            notEditableChildren={<VitalsNonEditable form={form} />}
            onAbortEdit={resetVitals}
            form={form}
            inputValues={vitalKeys}
            editModeProps={editModeProps}
            editable={editable}
            sx={{
              width: '100%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          />
          <AssessmentCard
            title={t('forms:clinical.heading')}
            notEditableChildren={<ClinicalNonEditable form={form} />}
            editableChildren={<ClinicalEditable form={form} />}
            onAbortEdit={() => resetClinical(form, prefilledValues)}
            form={form}
            inputValues={clinicalParameterKeys}
            editable={editable}
            editModeProps={editModeProps}
            sx={{
              width: '100%',
              mt: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          />
          <AssessmentCard
            title={t('forms:lab.heading')}
            notEditableChildren={<LabNonEditable form={form} />}
            editableChildren={<LabEditable form={form} />}
            onAbortEdit={() => resetLab(form, prefilledValues)}
            form={form}
            editModeProps={editModeProps}
            editable={editable}
            inputValues={labParameterKeys}
            sx={{
              width: '100%',
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              my: 0,
            }}
          />
          <AssessmentCard
            title={t('forms:imaging.heading')}
            notEditableChildren={<ImagingNonEditable form={form} />}
            editableChildren={<ImagingEditable form={form} />}
            onAbortEdit={() => resetImaging(form, prefilledValues)}
            form={form}
            inputValues={imagingParameterKeys}
            editable={editable}
            editModeProps={editModeProps}
            sx={{
              width: '100%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          />
        </Grid>

        <Grid
          item
          sm={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AssessmentCard
            title={t('forms:medicalHistory.heading')}
            editableChildren={
              <MedicalHistoryEditable
                form={form}
                showHeartFailureSubparameters={showHeartFailureSubparameters}
              />
            }
            notEditableChildren={
              <MedicalHistoryNonEditable
                form={form}
                showHeartFailureSubparameters={showHeartFailureSubparameters}
              />
            }
            onAbortEdit={() => resetMedicalHistory(form, prefilledValues)}
            form={form}
            inputValues={medicalHistoryKeys}
            editable={editable}
            editModeProps={editModeProps}
            sx={{ width: '100%' }}
          />
          <AssessmentCard
            title={t('forms:medications.heading')}
            editableChildren={<MedicationsEditable form={form} chosenStatinOption={statin} />}
            notEditableChildren={<MedicationsNonEditable form={form} />}
            onAbortEdit={() => resetMedication(form, prefilledValues)}
            form={form}
            editModeProps={editModeProps}
            inputValues={medicationKeys}
            editable={editable}
            sx={{ width: '100%', flex: 1, flexDirection: 'column', display: 'flex', my: 0 }}
          />
          <AssessmentCard
            title={t('forms:treatment.heading')}
            editableChildren={<TreatmentEditable form={form} />}
            notEditableChildren={<TreatmentNonEditable form={form} />}
            onAbortEdit={() => resetTreatment(form, prefilledValues)}
            form={form}
            inputValues={treatmentKeys}
            editable={editable}
            editModeProps={editModeProps}
            sx={{ width: '100%' }}
          />
        </Grid>
        <ErrorDialogue open={open} setOpen={setOpenErrorDialogue} form={form} />
      </Grid>

      {onSubmit && <ConsentSubmit form={form} submitHandler={onSubmit} />}
    </>
  );
}

function postprocessForAntiplateletMedication(form: AssessmentFormType) {
  if (form.getValues('ASS') || form.getValues('P2Yi')) {
    form.setValue('antiplateletMedication', true);
  }
}
