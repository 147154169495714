import axios, { AxiosError } from 'axios';
import { PATIENTS_URL } from '../config/urlConfiguration';
import { PreventRisks, RiskComponents, Risks } from '../types/risk';
import { useQuery } from 'react-query';
import { DocumentSection } from '../components/core/DocumentPopup/document.types';
import { getRedoxPatient } from './redox';
import { Assessment, AssessmentDifference, AssessmentParameters } from '../types/assessments';
import { AssessmentIdOrLatest } from './report';

/*
t('forms:genderValues.female', 'Female')
t('forms:genderValues.male', 'Male')
 */
export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
}

/*
t('forms:raceValues.asian', 'Asian')
t('forms:raceValues.black', 'Black')
t('forms:raceValues.caucasian', 'Caucasian')
t('forms:raceValues.hispanic', 'Hispanic')
t('forms:raceValues.mixed', 'Mixed')
t('forms:raceValues.other', 'Other')
 */
export enum Race {
  ASIAN = 'asian',
  BLACK = 'black',
  CAUCASIAN = 'caucasian',
  HISPANIC = 'hispanic',
  MIXED = 'mixed',
  OTHER = 'other',
  UNKNOWN = 'unknown',
}

export interface PatientParameters {
  firstName: string;
  lastName: string;
  birthday: string;
  gender: Gender;
  race: Race;
}

export interface Patient extends PatientParameters {
  id: number;
  userId: number;
}

export interface RiskParameters extends AssessmentParameters {
  age: number;
  gender: Gender;
  race: Race;
}

function getParameters({
  firstName,
  lastName,
  birthday,
  gender,
  race,
}: Patient): PatientParameters {
  return {
    firstName,
    lastName,
    birthday,
    gender,
    race,
  };
}

async function createPatient(patientParameters: PatientParameters): Promise<Patient> {
  return (await axios.post(PATIENTS_URL, patientParameters)).data;
}

async function deletePatient(id: number): Promise<void> {
  return (await axios.delete(`${PATIENTS_URL}/${id}`)).data;
}

async function update(patient: Patient): Promise<Patient> {
  return (await axios.put(`${PATIENTS_URL}/${patient.id}`, getParameters(patient))).data;
}

async function getPatientById(id: string): Promise<Patient> {
  return (await axios.get(`${PATIENTS_URL}/${id}`)).data;
}

// Used for "non-redox" flow
export function usePatientById(id: string) {
  return useQuery<Patient, AxiosError>(['patient', id], () => getPatientById(id));
}

//Used with "redox"-flow
export function usePatientBySessionId(id: string) {
  return useQuery<Patient, AxiosError>(['patient', id], () => getRedoxPatient(id));
}

async function getSearchResult(name: string): Promise<Patient[]> {
  return (await axios.get(`${PATIENTS_URL}?name=${name}`)).data;
}

export function usePatientsSearch(name: string) {
  return useQuery<Patient[], AxiosError>(['patients', name], () => getSearchResult(name));
}

export interface Recommendation {
  key: string;
  title: string;
  information: Array<DocumentSection>;
  text: string;
  disabled: boolean;
  applied: boolean;
  recommendedParameters: Partial<RiskParameters>;
}

export interface EvaluationResult {
  date: Date;
  currentRisks: Risks;
  adjustedRisks: Risks;
  recommendations: Recommendation[];
}

export interface TrendComponent {
  assignmentId: AssessmentIdOrLatest;
  date: Date;
  risks: RiskComponents;
}

export interface AssessmentComponent {
  assessmentId: number;
  date: Date;
}

export interface RiskDifferenceComponent extends RiskComponents {
  fromAssessmentId: number;
  toAssessmentId: number;
  patientId: number;
}

export interface Diagnosis {
  atrialFibrillation: boolean;
  coronaryArteryDisease: boolean;
  heartFailure: boolean;
  carotidArteryDisease: boolean;
  peripheralArteryDisease: boolean;
  historyOfStroke: boolean;
  transientIschemicAttack: boolean;
  thromboembolism: boolean;
  historyOfBleeding: boolean;
  chronicKidneyDisease: boolean;
  diabetes: boolean;
  hypertension: boolean;
  dementia: boolean;
}

export interface EvaluationResultCKM {
  date: Date;
  currentRisks: PreventRisks;
  adjustedRisks: PreventRisks;
  recommendations: Recommendation[];
}

export interface DiagnosisComponent extends AssessmentComponent, Diagnosis {}

async function getEvaluationResultOfAssessment(
  patientId: string,
  assessmentId: string,
): Promise<EvaluationResult> {
  const result = (
    await axios.get(`${PATIENTS_URL}/${patientId}/assessment/${assessmentId}/evaluate`)
  ).data;
  return {
    ...result,
    date: new Date(result.date),
  };
}

async function getEvaluationResultOfAssessmentCKM(
  patientId: string,
  assessmentId: string,
): Promise<EvaluationResultCKM> {
  const result = (
    await axios.get(`${PATIENTS_URL}/${patientId}/assessment/${assessmentId}/evaluateCKM`)
  ).data;
  return {
    ...result,
    date: new Date(result.date),
  };
}

export function useEvaluationResultOfAssessment(patientId: string, assessmentId: string) {
  return useQuery<EvaluationResult, AxiosError>(
    ['patient', patientId, 'assessmentId', assessmentId, 'evaluate'],
    () => getEvaluationResultOfAssessment(patientId, assessmentId),
  );
}

export function useEvaluationResultOfAssessmentCKM(patientId: string, assessmentId: string) {
  return useQuery<EvaluationResultCKM, AxiosError>(
    ['patient', patientId, 'assessmentId', assessmentId, 'evaluateCKM'],
    () => getEvaluationResultOfAssessmentCKM(patientId, assessmentId),
  );
}

async function getAdjustedRisksForAssessment(
  patientId: string,
  assessmentId: string,
  appliedRecommendations: string[],
) {
  return (
    await axios.get(`${PATIENTS_URL}/${patientId}/assessment/${assessmentId}/adjustedRisks`, {
      params: {
        appliedRecommendations,
      },
    })
  ).data;
}

async function getAdjustedRisksForAssessmentCKM(
  patientId: string,
  assessmentId: string,
  appliedRecommendations: string[],
) {
  return (
    await axios.get(`${PATIENTS_URL}/${patientId}/assessment/${assessmentId}/adjustedRisksCKM`, {
      params: {
        appliedRecommendations,
      },
    })
  ).data;
}

export function useAdjustedRisksOfAssessment(
  patientId: string,
  assessmentId: string,
  recommendations: Recommendation[],
  enabled: boolean,
) {
  const appliedRecommendations = recommendations.filter((r) => r.applied).map((r) => r.key);
  return useQuery<Risks, AxiosError>(
    ['patient', patientId, 'assessment', assessmentId, 'adjustedRisks', appliedRecommendations],
    () => getAdjustedRisksForAssessment(patientId, assessmentId, appliedRecommendations),
    { enabled },
  );
}

export function useAdjustedRisksOfAssessmentCKM(
  patientId: string,
  assessmentId: string,
  recommendations: Recommendation[],
  enabled: boolean,
) {
  const appliedRecommendations = recommendations.filter((r) => r.applied).map((r) => r.key);
  return useQuery<PreventRisks, AxiosError>(
    ['patient', patientId, 'assessment', assessmentId, 'adjustedRisksCKM', appliedRecommendations],
    () => getAdjustedRisksForAssessmentCKM(patientId, assessmentId, appliedRecommendations),
    { enabled },
  );
}

async function getTrend(patientId: number): Promise<TrendComponent[]> {
  const result = await axios.get(`${PATIENTS_URL}/${patientId}/trend`);
  return result.data.map(
    ({
      date,
      risks,
      assessmentId,
    }: {
      date: string;
      risks: RiskComponents;
      assessmentId: number;
    }) => ({
      date: new Date(date),
      assignmentId: assessmentId,
      risks: {
        mortality: (risks.mortality * 100).toFixed(1),
        stroke: (risks.stroke * 100).toFixed(1),
        bleeding: (risks.bleeding * 100).toFixed(1),
      },
    }),
  );
}

async function getDiagnosisComponent(
  patientId: number,
  months: number,
): Promise<DiagnosisComponent[]> {
  const { data } = await axios.get(`${PATIENTS_URL}/${patientId}/diagnosis?months=${months}`);
  return data.map((assessment: Assessment) => ({
    assessmentId: assessment.id,
    date: new Date(assessment.createdAt),
    ...assessment,
  }));
}

async function getDiagnosisData(patientId: number, months: number): Promise<Diagnosis[]> {
  const { data } = await axios.get(`${PATIENTS_URL}/${patientId}/diagnosis?months=${months}`);
  return data.map((assessment: Assessment) => ({
    ...assessment,
  }));
}

async function getNewTrend(patientId: number, months: number): Promise<TrendComponent[]> {
  const result = await axios.get(`${PATIENTS_URL}/${patientId}/newtrend?months=${months}`);
  return result.data.map(
    ({
      date,
      risks,
      assessmentId,
    }: {
      date: string;
      risks: RiskComponents;
      assessmentId: number;
    }) => ({
      date: new Date(date),
      assignmentId: assessmentId,
      risks: {
        mortality: (risks.mortality * 100).toFixed(1),
        stroke: (risks.stroke * 100).toFixed(1),
        bleeding: (risks.bleeding * 100).toFixed(1),
      },
    }),
  );
}

async function getAssessments(patientId: number | string): Promise<AssessmentComponent[]> {
  const result = await axios.get(`${PATIENTS_URL}/${patientId}/assessments`);
  return result.data.map(({ createdAt, id }: Assessment) => ({
    assessmentId: id,
    date: new Date(createdAt),
  }));
}

async function getRiskDifference(
  patientId: number,
  fromAssessmentId: number,
  toAssessmentId: number,
  absolute: boolean,
): Promise<RiskDifferenceComponent> {
  const result = await axios.get(
    `${PATIENTS_URL}/${patientId}/riskDifference?fromAssessment=${fromAssessmentId}&toAssessment=${toAssessmentId}&absolute=${absolute}`,
  );
  return {
    ...result.data,
    mortality: (result.data.mortality * 100).toFixed(1),
    stroke: (result.data.stroke * 100).toFixed(1),
    bleeding: (result.data.bleeding * 100).toFixed(1),
  };
}

export function useTrend(patientId: number) {
  return useQuery<TrendComponent[], AxiosError>(['patient', patientId, 'trend'], () =>
    getTrend(patientId),
  );
}

export function useNewTrend(patientId: number, months: number) {
  return useQuery<TrendComponent[], AxiosError>(
    ['patient', patientId, 'trend', 'months', months],
    () => getNewTrend(patientId, months),
  );
}

export function useAssessments(patientId: number | string) {
  return useQuery<AssessmentComponent[], AxiosError>(['patient', patientId, 'assessments'], () =>
    getAssessments(patientId),
  );
}

export function useDiagnosisComponent(patientId: number, months: number) {
  return useQuery<DiagnosisComponent[], AxiosError>(
    ['patient', patientId, 'diagnosisComponent', 'months', months],
    () => getDiagnosisComponent(patientId, months),
  );
}

export function useDiagnosisData(patientId: number, months: number) {
  return useQuery<Diagnosis[], AxiosError>(['patient', patientId, 'diagnosisData'], () =>
    getDiagnosisData(patientId, months),
  );
}

export function useAssessmentDifference(patientId: number, assessmentId: AssessmentIdOrLatest) {
  return useQuery<AssessmentDifference, AxiosError>(
    ['patient', patientId, 'assessment', assessmentId, 'assessmentDifference'],
    () => getAssessmentDifference(patientId, assessmentId),
  );
}

export function useRiskDifference(
  patientId: number,
  fromAssessmentId: number,
  toAssessmentId: number,
  absolute: boolean,
) {
  return useQuery<RiskDifferenceComponent, AxiosError>(
    [
      'patient',
      patientId,
      'fromAssessment',
      fromAssessmentId,
      'toAssessment',
      toAssessmentId,
      'riskDifference',
      absolute,
      'absolute',
    ],
    () => getRiskDifference(patientId, fromAssessmentId, toAssessmentId, absolute),
  );
}

export async function getAssessmentDifference(
  patientId: number | undefined,
  assessmentId: AssessmentIdOrLatest | undefined,
): Promise<AssessmentDifference> {
  return (await axios.get(`${PATIENTS_URL}/${patientId}/assessmentDifference?to=${assessmentId}`))
    .data;
}

export async function getAssessmentDifferenceFromTo(
  patientId: number | undefined,
  fromAssessmentId: number | undefined,
  toAssessmentId: number | undefined,
): Promise<AssessmentDifference> {
  return (
    await axios.get(
      `${PATIENTS_URL}/${patientId}/assessmentDifference?from=${fromAssessmentId}&to=${toAssessmentId}`,
    )
  ).data;
}

export const patientsApi = {
  createPatient,
  deletePatient,
  update,
};
