import { IconButton, Tooltip } from '@mui/material';
import React, { ReactFragment } from 'react';
import { InfoIcon } from '../../customIcons/InfoIcon';

interface InfoTooltipProps {
  text: string | ReactFragment;
}

export default function InfoTooltip(props: InfoTooltipProps) {
  return (
    <Tooltip disableFocusListener disableTouchListener title={props.text}>
      <sup>
        <IconButton size={'small'}>
          <InfoIcon style={{ fontSize: 16 }} color={'primary'} />
        </IconButton>
      </sup>
    </Tooltip>
  );
}
