import { Box, Typography } from '@mui/material';
import React from 'react';
import { RiskHistory } from '../../../../types/risk';
import { IndividualRisk } from './IndividualRisk';
import { useTranslation } from 'react-i18next';
import InfoTooltip from '../../../core/input-components/InfoTooltip/InfoTooltip';

interface RisksProps {
  riskHistory: RiskHistory;
}

const MAX_RISK_VALUE = 0.07;

export default function Risks(props: RisksProps) {
  const { t } = useTranslation('pages');

  const riskValues = [
    props.riskHistory.before.mortality,
    props.riskHistory.before.stroke,
    props.riskHistory.before.bleeding,
    props.riskHistory.after.mortality,
    props.riskHistory.after.stroke,
    props.riskHistory.after.bleeding,
  ];

  const maxRiskValue = Math.max(...riskValues, MAX_RISK_VALUE) * 100;

  return (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h6" sx={{ mb: 0 }}>
        {t('patientProfile.evaluation.risks.individualRiskProfile')}
        <InfoTooltip
          text={
            <ul>
              <li>{t('patientProfile.evaluation.risks.individualRiskProfileTooltip.item1')}</li>
              <li>{t('patientProfile.evaluation.risks.individualRiskProfileTooltip.item2')}</li>
            </ul>
          }
        />
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {t('patientProfile.evaluation.risks.individualRiskProfileSubtitle')}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.mortalityRisk')}
          currentRisk={props.riskHistory.before.mortality}
          adjustedRisk={props.riskHistory.after.mortality}
          maxRiskValue={maxRiskValue}
        />
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.strokeRisk')}
          currentRisk={props.riskHistory.before.stroke}
          adjustedRisk={props.riskHistory.after.stroke}
          maxRiskValue={maxRiskValue}
        />
        <IndividualRisk
          title={t('patientProfile.evaluation.risks.bleedingRisk')}
          currentRisk={props.riskHistory.before.bleeding}
          adjustedRisk={props.riskHistory.after.bleeding}
          maxRiskValue={maxRiskValue}
        />
      </Box>
    </Box>
  );
}
