import React, { ReactNode } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Control, Controller, FieldErrors, Path } from 'react-hook-form';
import { StyledLabelWithNote } from './StyledLabelWithNote';

export interface RadioOption {
  value: string;
  label: string;
  tooltip?: string;
}

interface StyledRadioGroupProps<T> {
  name: Path<T>;
  label: ReactNode;
  options: RadioOption[];
  errors: FieldErrors;
  control: Control<T>;
  isColumn?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
}

export function StyledRadioGroup<T>(props: StyledRadioGroupProps<T>) {
  return (
    <FormControl error={props.errors[props.name]?.message}>
      {props.showLabel === undefined || props.showLabel ? (
        <FormLabel>{props.label}</FormLabel>
      ) : null}
      <Controller
        name={props.name}
        render={({ field }) => (
          <RadioGroup {...field} row={!Boolean(props.isColumn)}>
            {props.options.map(({ value, label, tooltip }, index) => (
              <React.Fragment key={value}>
                <FormControlLabel
                  disabled={props.disabled}
                  value={value}
                  control={<Radio color="primary" />}
                  label={
                    tooltip === undefined || tooltip === '' ? (
                      label
                    ) : (
                      <StyledLabelWithNote label={label} tooltip={tooltip} />
                    )
                  }
                />
                {index !== props.options.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </RadioGroup>
        )}
        control={props.control}
      />
      <FormHelperText>{props.errors[props.name]?.message}</FormHelperText>
    </FormControl>
  );
}
