import { AssessmentFormType } from '../../AssessmentForm';
import { Box, List } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { disabledMedicalHistoryKeys, tickableMedicalHistoryKeys } from './medicalHistory.logic';
import { NonEditableAssessmentListItem } from '../../subComponents/NonEditableAssessmentListItem';
import {
  AssessmentParameters,
  ImperialAssessmentParameters,
} from '../../../../../types/assessments';

export default function MedicalHistoryNonEditable({
  form,
  showHeartFailureSubparameters,
}: {
  form: AssessmentFormType;
  showHeartFailureSubparameters: boolean;
}) {
  const values = form.getValues();
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <Medication values={values} showHeartFailureSubparameters={showHeartFailureSubparameters} />
    </div>
  );
}

function Medication({
  values,
  showHeartFailureSubparameters,
}: {
  values: AssessmentParameters & ImperialAssessmentParameters;
  showHeartFailureSubparameters: boolean;
}) {
  const { t } = useTranslation('forms');

  return (
    <List disablePadding={true} sx={{ columnCount: 2, pb: 3 }} dense>
      {disabledMedicalHistoryKeys.map((field) => (
        <NonEditableAssessmentListItem
          isChecked={values[field]}
          withDivider={true}
          label={t(`medicalHistory.${field}.label`)}
          tooltip={t(`medicalHistory.${field}.tooltip`)}
          key={field}
        />
      ))}
      {tickableMedicalHistoryKeys
        .filter((key) => key !== 'heartFailure' && key !== 'HFpEF' && key !== 'HFrEF')
        .map((field, index, keys) => (
          <NonEditableAssessmentListItem
            isChecked={values[field]}
            withDivider={shouldHaveDivider(index, keys, showHeartFailureSubparameters)}
            label={t(`medicalHistory.${field}.label`)}
            tooltip={t(`medicalHistory.${field}.tooltip`)}
            key={field}
          />
        ))}
      <NonEditableAssessmentListItem
        isChecked={values['heartFailure']}
        withDivider={false}
        label={t(`medicalHistory.heartFailure.label`)}
        tooltip={t(`medicalHistory.heartFailure.tooltip`)}
        key={'heartFailure'}
      />
      {showHeartFailureSubparameters &&
        tickableMedicalHistoryKeys
          .filter((key) => key === 'HFpEF' || key === 'HFrEF')
          .map((field) => (
            <Box sx={{ pl: 3 }} key={field}>
              <NonEditableAssessmentListItem
                isChecked={values[field]}
                withDivider={false}
                label={t(`medicalHistory.${field}.label`)}
                tooltip={t(`medicalHistory.${field}.tooltip`)}
                key={field}
              />
            </Box>
          ))}
    </List>
  );
}

function shouldHaveDivider(
  index: number,
  array: Array<any>,
  showHeartFailureSubparameters: boolean,
) {
  return showHeartFailureSubparameters
    ? index !== array.length && index !== Math.ceil(array.length / 2)
    : index !== array.length && index !== Math.ceil(array.length / 2) - 1;
}
