import { Control, Controller, FieldErrors, Path } from 'react-hook-form';
import { RadioOption } from '../../../core/input-components/StyledRadioGroup';
import Grid from '@mui/material/Grid';
import { FormControl, FormHelperText, MenuItem, Select, Stack } from '@mui/material';
import LabelWithTooltip from '../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import { StyledLabelWithNote } from '../../../core/input-components/StyledLabelWithNote';
import React from 'react';
import { LineTitleLabel } from './DropdownNonEditable';

interface DropdownProps<T> {
  name: Path<T>;
  label: string;
  options: RadioOption[];
  errors: FieldErrors;
  control: Control<T>;
  tooltip?: string;
  isColumn?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
}

export function DropdownEditable<T>(props: DropdownProps<T>) {
  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={12} justifyContent={'center'}>
          {props.showLabel === undefined || props.showLabel ? (
            props.tooltip !== undefined && props.tooltip !== '' ? (
              <Stack direction={'row'}>
                <LabelWithTooltip
                  label={<LineTitleLabel text={props.label} />}
                  tooltipText={props.tooltip}
                />
              </Stack>
            ) : (
              <LineTitleLabel text={props.label} />
            )
          ) : null}
          <FormControl fullWidth error={!!props.errors[props.name]}>
            <Controller
              name={props.name}
              control={props.control}
              render={({ field }) => (
                <Select {...field} disabled={props.disabled}>
                  {props.options.map(({ value, label, tooltip }) => (
                    <MenuItem key={value} value={value}>
                      {tooltip ? <StyledLabelWithNote label={label} tooltip={tooltip} /> : label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{props.errors[props.name]?.message}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
