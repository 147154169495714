import React from 'react';
import { Box, Paper } from '@mui/material';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import useWithLoadingAndErrorIndication from '../../../hooks/loadingIndication/useWithLoadingAndErrorIndication';
import { usePatientById } from '../../../api/patients';
import { WebHeader } from '../WebheaderComponents/WebHeader';
import { DifferenceView } from './TrendComponents/Difference/DifferenceView';
import { TimeSeriesView } from './TrendComponents/TimeSeriesView/TimeSeriesView';
import { OptionsProvider } from './TrendComponents/Options/OptionsProvider';

interface TrendParams {
  patientId: string;
  subpage: string;
}

enum TrendSubpage {
  TIMESERIES = 'timeseries',
  DIFFERENCE = 'difference',
}
export function NewTrend() {
  const { patientId, subpage } = useParams() as TrendParams;
  const { data: patient } = useWithLoadingAndErrorIndication(usePatientById(patientId));

  if (!patient) {
    return <div />;
  }

  switch (subpage) {
    case TrendSubpage.TIMESERIES:
      return (
        <Container maxWidth={'lg'} sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
          <Box sx={{ p: 0 }}>
            <WebHeader patient={patient} showTrendButtons />
            <Paper elevation={1} sx={{ padding: [0, 8, 8, 8] }}>
              <OptionsProvider>
                <TimeSeriesView patientId={patient.id} />
              </OptionsProvider>
            </Paper>
          </Box>
        </Container>
      );
    case TrendSubpage.DIFFERENCE:
      return (
        <Container maxWidth={'lg'} sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
          <Box sx={{ p: 0 }}>
            <WebHeader patient={patient} showTrendButtons />
            <Paper elevation={1} sx={{ padding: [0, 8, 8, 8] }}>
              <OptionsProvider>
                <DifferenceView patientId={patient.id} />
              </OptionsProvider>
            </Paper>
          </Box>
        </Container>
      );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingTop: '2rem', paddingBottom: '3rem' }}>
      <Box sx={{ p: 0 }}>
        <Paper elevation={1} sx={{ padding: [0, 8, 0, 8] }}>
          <WebHeader patient={patient} showTrendButtons />
          <TimeSeriesView patientId={patient.id} />
        </Paper>
      </Box>
    </Container>
  );
}
