import { AssessmentFormType } from '../../AssessmentForm';
import { DEFAULT_ASSESSMENT_VALUES_METRIC } from '../../../../../config/defaultValues';
import { AssessmentParameters, Medication } from '../../../../../types/assessments';
import {
  DataRepresentations,
  getAllKeys,
  getBooleanValueKeys,
  getEnumValueKeys,
  RepresentationMapping,
} from '../../logic/DataRepresentations';

const medicationDataRepresentationMapping: RepresentationMapping<Medication> = {
  antiplateletMedication: DataRepresentations.tickableBoolean,
  oralAnticoagulant: DataRepresentations.enumValue,
  contraindicationForOAC: DataRepresentations.enumValue,
  antiArrhythmicMedication_Class1or3: DataRepresentations.enumValue,
  diabetesMedication: DataRepresentations.enumValue,
  ARNi: DataRepresentations.enumValue,
  statin: DataRepresentations.enumValue,
  statinDosage: DataRepresentations.other,
  metformin: DataRepresentations.tickableBoolean,
  EPA: DataRepresentations.tickableBoolean,
  ACE: DataRepresentations.tickableBoolean,
  ARB: DataRepresentations.tickableBoolean,
  ezetimibe: DataRepresentations.tickableBoolean,
  ASS: DataRepresentations.tickableBoolean,
  P2Yi: DataRepresentations.tickableBoolean,
  PCSK9i: DataRepresentations.tickableBoolean,
  betablocker: DataRepresentations.tickableBoolean,
  steroidalMRA: DataRepresentations.tickableBoolean,
  CCB: DataRepresentations.tickableBoolean,
  DPP4i: DataRepresentations.tickableBoolean,
  TZD: DataRepresentations.tickableBoolean,
  hydralazine_isosorbide: DataRepresentations.tickableBoolean,
  finerenone: DataRepresentations.tickableBoolean,
  proven_GLP1RA: DataRepresentations.tickableBoolean,
  other_GLP1RA: DataRepresentations.tickableBoolean,
  SGLT2i: DataRepresentations.tickableBoolean,
};

export type MedicationKeys = keyof Medication;

export const lipidLoweringAgentKeys: MedicationKeys[] = ['EPA', 'ezetimibe', 'PCSK9i', 'statin'];
export const antidiabeticsKeys: MedicationKeys[] = [
  'metformin',
  'DPP4i',
  'TZD',
  'proven_GLP1RA',
  'other_GLP1RA',
  'SGLT2i',
];
export const antihypertensivesKeys: MedicationKeys[] = ['ACE', 'ARB', 'betablocker', 'CCB'];
export const heartfailureMedicationKeys: MedicationKeys[] = [
  'ARNi',
  'steroidalMRA',
  'finerenone',
  'hydralazine_isosorbide',
];
export const antiplateletMedicationKeys: MedicationKeys[] = ['ASS', 'P2Yi'];
export const oralAnticoagulantKeys: MedicationKeys[] = ['oralAnticoagulant'];
export const antiArrhythmicMedication_Class1or3_keys: MedicationKeys[] = [
  'antiArrhythmicMedication_Class1or3',
];
export const contraindicationForOAC_keys: MedicationKeys[] = ['contraindicationForOAC'];

export const medicationKeysBooleanValues = getBooleanValueKeys(medicationDataRepresentationMapping);
export const medicationKeysEnumValues = getEnumValueKeys(medicationDataRepresentationMapping);

export const medicationKeys = getAllKeys(medicationDataRepresentationMapping);

export function resetMedication(
  form: AssessmentFormType,
  prefilledValues?: Partial<AssessmentParameters>,
) {
  form.reset({
    ...form.getValues(),
    ...Object.fromEntries(
      medicationKeys.map((key) => [
        key,
        prefilledValues?.[key] ?? DEFAULT_ASSESSMENT_VALUES_METRIC[key],
      ]),
    ),
  });
}
