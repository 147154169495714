import { AssessmentFormType } from '../../AssessmentForm';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { StyledRadioGroup } from '../../../../core/input-components/StyledRadioGroup';
import LabelWithTooltip from '../../../../core/input-components/LabelWithTooltip/LabelWithTooltip';
import { CCS_SAF_Score } from '../../../../../types/assessments';
import React from 'react';
import { StyledTextField } from '../../../../core/input-components/StyledTextField';
import { ruleHelper } from '../../logic/rule-helper';
import {
  allowedValueRangeForClinical,
  CLINICAL_UNITS,
  clinicalParameterKeys,
} from './Clinical.logic';
import { TFunction } from 'i18next';

export function ClinicalEditable({ form }: { form: AssessmentFormType }) {
  return (
    <div style={{ pageBreakInside: 'avoid' }}>
      <ClinicalInput form={form} />
    </div>
  );
}

function CCSSAFScoreInput({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <Grid container sx={{ mt: '20px' }}>
      <Grid item xs={12}>
        <StyledRadioGroup
          name={'CCS_SAF_score'}
          label={
            <LabelWithTooltip
              label={t('clinical.CCS_SAF_score.label')}
              tooltipText={t('clinical.CCS_SAF_score.tooltip')}
            />
          }
          options={Object.keys(CCS_SAF_Score)
            .filter((key) => {
              return !isNaN(Number(key)); // typescript is compiling enums with integer repr. in both ways, filter one out
            })
            .map((key) => ({
              ...t('clinical.CCS_SAF_score.values.' + key, { returnObjects: true }),
              value: key,
            }))}
          control={form.control}
          errors={form.formState.errors}
        />
      </Grid>
    </Grid>
  );
}

function ClinicalInput({ form }: { form: AssessmentFormType }) {
  const { t } = useTranslation('forms');

  return (
    <>
      {clinicalParameterKeys
        .filter((key) => key !== 'CCS_SAF_score')
        .map((fieldName) => (
          <Grid item xs={12} sm={6} md={12} sx={{ p: 0 }} key={fieldName}>
            <StyledTextField
              name={fieldName}
              type={fieldName === 'adverseSDOH' ? 'string' : 'number'}
              label={
                t(`clinical.${fieldName}.label`) +
                (t(`clinical.${fieldName}.tooltip`)
                  ? ` (${t(`clinical.${fieldName}.tooltip`)})`
                  : '')
              }
              register={
                fieldName !== 'adverseSDOH'
                  ? form.register(fieldName, ruleHelper(t, allowedValueRangeForClinical, fieldName))
                  : form.register(fieldName, zipCodeRule(t))
              }
              errors={form.formState.errors}
              adornment={CLINICAL_UNITS[fieldName]}
            />
          </Grid>
        ))}
      <CCSSAFScoreInput form={form} />
    </>
  );
}

function zipCodeRule(t: TFunction) {
  return {
    pattern: { value: /\d{5}$/, message: t('errors.zipCode') },
    disabled: false,
  };
}
