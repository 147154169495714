import {
  AntiArrhythmicMedication_Class_1or3,
  ARNiDosage,
  AssessmentParameters,
  CCS_SAF_Score,
  ContraindicationForOAC,
  DiabetesMedication,
  OralAnticoagulant,
  Statin,
} from '../types/assessments';

export const usePrefilledValuesForJamesLieAndJohnPotter = true;
export const PrefilledValuesForJamesLieAndJohnPotter: AssessmentParameters = {
  LADiameter: 49,
  LFEjectionFraction: 40,
  eGFR: 89,
  CCS_SAF_score: CCS_SAF_Score.Moderate,
  antiArrhythmicMedication_Class1or3: AntiArrhythmicMedication_Class_1or3.NotTried,
  antiplateletMedication: false,
  atrialFibrillation: true,
  carotidArteryDisease: false,
  chronicKidneyDisease: false,
  contraindicationForOAC: ContraindicationForOAC.None,
  coronaryArteryDisease: false,
  currentSmoker: true,
  dementia: false,
  diabetes: false,
  diabetesMedication: DiabetesMedication.NONE,
  diastolicBloodPressure: 85,
  heartFailure: false,
  height: 165,
  historyOfBleeding: false,
  historyOfStroke: false,
  hypertension: true,
  oralAnticoagulant: OralAnticoagulant.VKA,
  peripheralArteryDisease: false,
  pulse: 98,
  rhythmControlDesired: true,
  systolicBloodPressure: 140,
  thromboembolism: false,
  transientIschemicAttack: false,
  weight: 66,
  UACR: 10000,
  AER: null,
  HBA1c: 3,
  fastingBloodGlucose: null,
  triglycerides: null,
  totalCholesterol: 180,
  LDLCholesterol: null,
  HDLCholesterol: 80,
  NTproBNP: null,
  hsTroponinT: null,
  hsTroponinI: null,
  CAC: null,
  LVH: null,
  HFrEF: false,
  HFpEF: false,
  adverseSDOH: null,
  waistCircumference: null,

  statin: Statin.None,
  statinDosage: null,
  metformin: false,
  EPA: false,
  ACE: false,
  ARB: false,
  ARNi: ARNiDosage.None,
  ezetimibe: false,
  ASS: false,
  P2Yi: false,
  PCSK9i: false,
  betablocker: false,
  steroidalMRA: false,
  CCB: false,
  DPP4i: false,
  TZD: false,
  hydralazine_isosorbide: false,
  finerenone: false,
  proven_GLP1RA: false,
  other_GLP1RA: false,
  SGLT2i: false,
};
