import React, { PropsWithChildren, useState } from 'react';

export interface ActiveModules {
  AF_module: boolean;
  CKM_module: boolean;
}

export interface ActiveModulesSetter {
  setAF_module: (AF_module: boolean) => void;
  setCKM_module: (CKM_module: boolean) => void;
}

export const ActiveModuleContext = React.createContext({
  activeModules: {
    AF_module: true,
    CKM_module: false,
  },
  activeModulesSetter: {
    setAF_module: (AF_module: boolean) => {},
    setCKM_module: (CKM_module: boolean) => {},
  },
});

export function ActiveModulesProvider({ children }: PropsWithChildren<{}>) {
  const [AF_module, setAF_module] = useState<boolean>(true);
  const [CKM_module, setCKM_module] = useState<boolean>(false);
  const activeModules: ActiveModules = {
    AF_module: AF_module,
    CKM_module: CKM_module,
  };
  const activeModulesSetter: ActiveModulesSetter = {
    setAF_module: setAF_module,
    setCKM_module: setCKM_module,
  };
  return (
    <ActiveModuleContext.Provider
      value={{
        activeModules: activeModules,
        activeModulesSetter: activeModulesSetter,
      }}
    >
      {children}
    </ActiveModuleContext.Provider>
  );
}
